import SVGAmericanFlag from '../../assets/svg/SVGAmericanFlag';
import SVGChineseFlag from '../../assets/svg/SVGChineseFlag';
import SVGKoreanFlag from '../../assets/svg/SVGKoreanFlag';
import SVGRussianFlag from '../../assets/svg/SVGRussianFlag';
import SVGSpainFlag from '../../assets/svg/SVGSpainFlag';
import SVGTurkeyFlag from '../../assets/svg/SVGTurkeyFlag';
import SVGDiscrod from '../../assets/svg/socials/SVGDiscrod';
import SVGInstagram from '../../assets/svg/socials/SVGInstagram';
import SVGTelegram from '../../assets/svg/socials/SVGTelegram';
import SVGTwitter from '../../assets/svg/socials/SVGTwitter';
import SVGYoutube from '../../assets/svg/socials/SVGYoutube';
import SVGVk from '../../assets/svg/socials/SVGVk';

// language localstorage key;
export const LOCALES_LOCAL_STORAGE_KEY = 'i18nextLng';

export const DASHBOARD_LINK = 'https://dashboard.trinity-dao.com';

export const FLAGS_BY_KEY = {
  RU: <SVGRussianFlag />, // Русский
  EN: <SVGAmericanFlag />, // Английский
  ES: <SVGSpainFlag />, // Испанский
  KO: <SVGKoreanFlag />, // Корейский
  CH: <SVGChineseFlag />, // Китайский
  TU: <SVGTurkeyFlag />, // Турецкий
};

export const LANGUAGES_LIST = [
  {
    label: 'Русский',
    letters: 'ru',
  },
  {
    label: 'English',
    letters: 'en',
  },
  {
    label: 'Español',
    letters: 'es',
  },
  {
    label: '한국어',
    letters: 'ko',
  },
  {
    label: '中文',
    letters: 'ch',
  },
  {
    label: 'Türkçe',
    letters: 'tu',
  },
];

export const TILT_DEFAULT_SETTINGS = {
  reverse: false,
  max: 35,
  perspective: 1000,
  scale: 1,
  speed: 2000,
  transition: true,
  axis: null,
  reset: false,
  easing: 'cubic-bezier(.03,.98,.52,.99)',
};

export const SOCIALS_LIST = [
  {
    title: 'Telegram',
    link: 'social.telegram',
    icon: <SVGTelegram />,
  },
  {
    title: 'YouTube',
    link: 'social.youtube',
    icon: <SVGYoutube />,
  },
  {
    title: 'Twitter',
    link: 'social.twitter',
    icon: <SVGTwitter />,
  },
  {
    title: 'Instagram',
    link: 'social.instagram',
    icon: <SVGInstagram />,
  },
  {
    title: 'VK',
    link: 'social.vk',
    icon: <SVGVk />,
  },
  {
    title: 'discord',
    link: 'social.discord',
    icon: <SVGDiscrod />,
  },
];
