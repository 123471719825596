import SVGButtonArrow from '../../assets/svg/SVGButtonArrow';

export default function SliderArrowNext({ onClick }) {
  return (
    <button
      className="default-slick-arrow default-slick-arrow--next"
      onClick={onClick}
    >
      <SVGButtonArrow />
    </button>
  );
}
