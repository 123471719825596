import { AnimatePresence, motion } from 'framer-motion';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import SVGDevice from '../../assets/svg/SVGDevice';
import SVGForsage from '../../assets/svg/SVGForsage';
import SVGProperty from '../../assets/svg/SVGProperty';
import SVGLineBig from '../../assets/svg/SVGLineBig';
import Device from '../../assets/img/programs/device.png';
import Forsage from '../../assets/img/programs/forsage.png';
import Property from '../../assets/img/programs/property.png';
import Planet from '../../assets/img/planet.svg';
import Hand from '../../assets/img/hand.png';
import Coin from '../../assets/img/coin.png';
import SVGArrowDown from '../../assets/svg/SVGArrowDown';
import SVGButtonArrow from '../../assets/svg/SVGButtonArrow';
import SliderArrowNext from '../../components/SliderArrowNext';
import SliderArrowPrev from '../../components/SliderArrowPrev';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import SafeHTML from '../../components/SafeHTML';

export function BlockStep1() {
  const { t } = useCustomTranslation();
  const slider = useRef();
  const sliderSettings = {
    arrows: false,
    infinite: false,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
    responsive: [
      {
        breakpoint: 9999,
        settings: 'unslick',
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true,
          arrows: true,
        },
      },
    ],
  };

  return (
    <div className="steps__block">
      <div className="steps__block--title">
        <span>
          {t('stepsBlock.list.element1.title')}
          <SVGLineBig />
        </span>
        <br />
        {t('stepsBlock.list.element1.subTitle')}
        <div className="steps__block--number">1</div>
      </div>
      <SafeHTML html={t('stepsBlock.list.element1.topText')}>
        <div className="steps__block--desc" />
      </SafeHTML>
      <Slider
        className="steps__block--content"
        ref={slider}
        {...sliderSettings}
      >
        <div className="step-box--holder">
          <div className="step-box">
            <div className="step-box__title">
              <SVGDevice />
              {t('stepsBlock.list.element1.devices')}
            </div>
            <div className="step-box__picture">
              <img src={Device} alt="" />
            </div>
          </div>
        </div>
        <div className="step-box--holder">
          <div className="step-box">
            <div className="step-box__title">
              <SVGForsage />
              {t('stepsBlock.list.element1.cars')}
            </div>
            <div className="step-box__picture">
              <img src={Forsage} alt="" />
            </div>
          </div>
        </div>
        <div className="step-box--holder">
          <div className="step-box">
            <div className="step-box__title">
              <SVGProperty />
              {t('stepsBlock.list.element1.property')}
            </div>
            <div className="step-box__picture">
              <img src={Property} alt="" />
            </div>
          </div>
        </div>
      </Slider>
      <SafeHTML html={t('stepsBlock.list.element1.bottomText')}>
        <div className="steps__block--footer" />
      </SafeHTML>
    </div>
  );
}

export function BlockStep2() {
  const { t } = useCustomTranslation();
  return (
    <div className="steps__block">
      <div className="steps__block--title">
        <span>
          {t('stepsBlock.list.element2.title')}
          <SVGLineBig />
        </span>
        <br />
        {t('stepsBlock.list.element2.subTitle')}
        <div className="steps__block--number">2</div>
      </div>
      <SafeHTML html={t('stepsBlock.list.element2.topText')}>
        <div className="steps__block--desc" />
      </SafeHTML>
      <div className="steps__block--planet">
        <div className="steps__block--planet__holder">
          <span className="light --first" />
          <span className="light --second" />
          <img src={Planet} alt="" />
        </div>
      </div>
      <SafeHTML html={t('stepsBlock.list.element2.bottomText')}>
        <div className="steps__block--footer" />
      </SafeHTML>
    </div>
  );
}

export function BlockStep3() {
  const { t } = useCustomTranslation();
  return (
    <div className="steps__block">
      <div className="steps__block--title">
        <span>
          {t('stepsBlock.list.element3.title')}
          <SVGLineBig />
        </span>
        <br />
        {t('stepsBlock.list.element3.subTitle')}
        <div className="steps__block--number">3</div>
      </div>
      <SafeHTML html={t('stepsBlock.list.element3.topText')}>
        <div className="steps__block--desc" />
      </SafeHTML>
      <div className="container">
        <div className="steps__block--hands-wrapper">
          <div className="steps__block--hand --first">
            <img src={Hand} alt="" />
          </div>
          <div className="steps__block--coin">
            <img src={Coin} alt="" />
          </div>
          <div className="steps__block--hand --second">
            <img src={Hand} alt="" />
          </div>
        </div>
      </div>
      <SafeHTML html={t('stepsBlock.list.element3.bottomText')}>
        <div className="steps__block--footer" />
      </SafeHTML>
    </div>
  );
}

export default function BlockSteps() {
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useCustomTranslation();
  const scrollHolder = useRef();
  const scrollContainer = useRef();
  const steps = [
    {
      step: 1,
      block: <BlockStep1 />,
    },
    {
      step: 2,
      block: <BlockStep2 />,
    },
    {
      step: 3,
      block: <BlockStep3 />,
    },
  ];
  return (
    <AnimatePresence>
      <div className="steps__holder" ref={scrollHolder}>
        <div className="steps" ref={scrollContainer}>
          <div className="steps__top-title">
            <span>
              {t('stepsBlock.topTitle')}
            </span>
          </div>
          <ul className="steps__list">
            {steps.map((element, key) => (
              <li
                key={`step-selector-${key.toString()}`}
                className={`${activeStep === key ? '--active' : ''}`}
                onClick={() => setActiveStep(key)}
              >
                <div className="steps__list--title">
                  {t('stepsBlock.step')}
                </div>
                <div className="steps__list--number">
                  <span>
                    {element?.step}
                  </span>
                </div>
              </li>
            ))}
          </ul>
          <div className="steps__holder">
            {activeStep >= 1 && (
              <motion.div
                className="steps__arrow steps__arrow--back"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={() => setActiveStep((current) => current - 1)}
              >
                <SVGButtonArrow />
              </motion.div>
            )}
            {activeStep < steps.length - 1 && (
              <motion.div
                className="steps__arrow steps__arrow--next"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={() => setActiveStep((current) => current + 1)}
              >
                <SVGButtonArrow />
              </motion.div>
            )}
            {steps.map((element, key) => (
              activeStep === key
                ? element.block
                : null
            ))}
          </div>
          <div className="steps__scroll-down">
            <SVGArrowDown />
          </div>
        </div>
      </div>
    </AnimatePresence>
  );
}
