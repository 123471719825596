export default function SVGScrollArrow({
  fill = 'currentColor', opacity = 1,
}) {
  return (
    <svg width="20" height="108" viewBox="0 0 20 108" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path d="M10.6048 107.749L19.2411 99.1127C19.6033 98.8025 19.6456 98.2573 19.3353 97.895C19.025 97.5327 18.4798 97.4905 18.1176 97.8008C18.0838 97.8298 18.0523 97.8612 18.0234 97.895L10.8553 105.054L10.8553 0.863594C10.8553 0.386672 10.4687 -3.96651e-07 9.99166 -4.17501e-07C9.51466 -4.38351e-07 9.12806 0.386672 9.12806 0.863594L9.12806 105.054L1.96867 97.895C1.65844 97.5327 1.11324 97.4905 0.750984 97.8008C0.388729 98.1111 0.3465 98.6562 0.656803 99.0185C0.685747 99.0523 0.717214 99.0838 0.750984 99.1127L9.38722 107.749C9.72395 108.084 10.268 108.084 10.6048 107.749Z" fill={fill} />
      </g>
    </svg>
  );
}
