export default function SVGAmericanFlag() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75" fill="none">
      <path d="M37.3228 -0.000175476C57.9357 -0.000175476 74.6457 16.7098 74.6457 37.3227C74.6457 57.9355 57.9357 74.6455 37.3228 74.6455C16.71 74.6455 0 57.9355 0 37.3227C0 16.7098 16.71 -0.000175476 37.3228 -0.000175476Z" fill="#F0F0F0" />
      <path d="M7.71474 60.043C4.78301 56.2286 2.57236 51.8322 1.28516 47.0562H20.7015L7.71474 60.043Z" fill="#0052B4" />
      <path d="M73.3535 47.0564C72.0663 51.8322 69.8555 56.2286 66.924 60.043L53.9375 47.0564H73.3535Z" fill="#0052B4" />
      <path d="M1.28516 27.5869C2.5725 22.811 4.78315 18.4147 7.71474 14.6005L20.7011 27.5869H1.28516Z" fill="#0052B4" />
      <path d="M60.0413 66.9296C56.2269 69.8614 51.8307 72.072 47.0547 73.3594V53.9432L60.0413 66.9296Z" fill="#0052B4" />
      <path d="M14.6133 7.71291C18.4276 4.78117 22.824 2.57052 27.5999 1.28318V20.6992L14.6133 7.71291Z" fill="#0052B4" />
      <path d="M27.5997 73.3594C22.8239 72.072 18.4275 69.8614 14.6133 66.9298L27.5997 53.9433V73.3594Z" fill="#0052B4" />
      <path d="M47.0547 1.28318C51.8306 2.57052 56.2269 4.78118 60.0411 7.71277L47.0547 20.6992V1.28318Z" fill="#0052B4" />
      <path d="M53.9375 27.5869L66.924 14.6003C69.8555 18.4145 72.0663 22.811 73.3535 27.5869H53.9375Z" fill="#0052B4" />
      <path d="M74.3298 42.1908H42.1913H42.1911V74.3296C40.5975 74.537 38.9728 74.6455 37.3228 74.6455C35.6726 74.6455 34.0482 74.537 32.4547 74.3296V42.1911V42.191H0.315932C0.10847 40.5973 0 38.9726 0 37.3227C0 35.6724 0.10847 34.048 0.315932 32.4545H32.4544H32.4545V0.315755C34.0482 0.108293 35.6726 -0.000175476 37.3228 -0.000175476C38.9728 -0.000175476 40.5975 0.108147 42.191 0.315755V32.4542V32.4544H74.3298C74.5372 34.048 74.6457 35.6724 74.6457 37.3227C74.6457 38.9726 74.5372 40.5973 74.3298 42.1908Z" fill="#D80027" />
      <path d="M47.0664 27.5868L63.7211 10.932C64.4871 11.6977 65.2178 12.4983 65.915 13.3281L51.6562 27.5869H47.0664V27.5868Z" fill="#D80027" />
      <path d="M27.5728 27.5869H27.5726L10.918 10.9323C11.6837 10.1663 12.4842 9.43562 13.3141 8.73844L27.5728 22.9975V27.5869Z" fill="#D80027" />
      <path d="M27.5791 47.0567V47.057L10.9244 63.7119C10.1584 62.9462 9.42765 62.1457 8.73047 61.3158L22.9894 47.0569L27.5791 47.0567Z" fill="#D80027" />
      <path d="M47.0664 47.0565L63.7213 63.7115C62.9556 64.4775 62.155 65.2082 61.3252 65.9053L47.0664 51.6463V47.0565Z" fill="#D80027" />
    </svg>
  );
}
