import Planet from '../../assets/img/earth.png';
import SVGIdeaDao from '../../assets/svg/SVGIdeaDao';
import SVGLineBig from '../../assets/svg/SVGLineBig';
import SafeHTML from '../../components/SafeHTML';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';

export default function BlockConsumerFund() {
  const { t } = useCustomTranslation();
  return (
    <div className="consumer-fund">
      <div className="container">
        <div className="consumer-fund__holder">
          <div className="consumer-fund__box">
            <div className="consumer-fund__planet">
              <img src={Planet} alt="" />
              <div className="consumer-fund__planet--background">
                <SVGIdeaDao />
                <SVGIdeaDao />
              </div>
            </div>
            <div className="consumer-fund__top-title">
              {t('blockConsumerFund.topTitle')}
            </div>
            <div className="consumer-fund__title">
              <span>
                {t('blockConsumerFund.title')}
              </span>
              <SVGLineBig />
            </div>
            <SafeHTML html={t('blockConsumerFund.text')}>
              <div className="consumer-fund__text" />
            </SafeHTML>
          </div>
          <div className="consumer-fund__logo">
            <SVGIdeaDao />
          </div>
        </div>
      </div>
    </div>
  );
}
