export default function Particles({
  count = 20,
}) {
  return (
    <>
      {[...Array(count)].map((e, key) => (
        <div key={`particle-${key}`} className="particle" />
      ))}
    </>
  );
}
