import SVGBinance from '../../assets/svg/SVGBinance';
import SVGLineBig from '../../assets/svg/SVGLineBig';
import SVGUsdt from '../../assets/svg/SVGUsdt';
import Robert from '../../assets/img/roberto.png';
import Rat from '../../assets/img/rat.png';
import SVGScrollArrow from '../../assets/svg/SVGScrollArrow';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import SVGIdeaDao from '../../assets/svg/SVGIdeaDao';
import SVGPlus from '../../assets/svg/SVGPlus';
import SafeHTML from '../../components/SafeHTML';

export default function RatRaceBlock() {
  const { t } = useCustomTranslation();

  return (
    <div className="rat-race-holder" id="mission">
      <div className="rat-race">
        <div className="rat-race__plus rat-race__plus--first">
          <SVGPlus fill="rgba(168, 99, 237, 0.4)" />
          <SVGPlus stroke="#A863ED" />
        </div>
        <div className="rat-race__plus rat-race__plus--second">
          <SVGPlus fill="rgba(168, 99, 237, 0.4)" />
          <SVGPlus stroke="#A863ED" />
        </div>
        <div className="rat-race__plus rat-race__plus--thrid">
          <SVGPlus fill="rgba(168, 99, 237, 0.4)" />
          <SVGPlus stroke="#A863ED" />
        </div>
        <div className="container">
          <div className="rat-race__title">
            <span>{t('ratRaceBlock.title')}</span>
            <SVGLineBig />
          </div>
          <SafeHTML html={t('ratRaceBlock.subtitle')}>
            <div className="rat-race__subtitle" />
          </SafeHTML>
          <div className="rat-race__row">
            <div className="rat-race__cell --left">
              <div className="rat-race__roberto">
                <div className="rat-race__roberto--picture">
                  <img src={Robert} alt="" />
                </div>
                <div className="rat-race__roberto--name">
                  {t('ratRaceBlock.robert.name')}
                </div>
                <div className="rat-race__roberto--about">
                  {t('ratRaceBlock.robert.job')}
                </div>
                <div className="rat-race__roberto--description">
                  {t('ratRaceBlock.robert.service')}
                </div>
              </div>
            </div>
            <div className="rat-race__cell --right">
              <div className="rat-race__block-holder">
                <SVGIdeaDao />
                <div className="rat-race__block">
                  <div className="rat-race__block--cell">
                    <div className="rat-race__block--title">
                      {t('ratRaceBlock.ratRaceOriginal')}
                      <div className="rat-race__block--picture">
                        <img src={Rat} alt="" />
                      </div>
                    </div>
                    <div className={`rat-race__block--subtitle ${t('classPrefix')}`}>
                      {t('ratRaceBlock.ratRace')}
                    </div>
                    <SafeHTML html={t('ratRaceBlock.text')}>
                      <div className="rat-race__block--text" />
                    </SafeHTML>
                  </div>
                </div>
              </div>
              <div className="rat-race__solution">
                <div className="rat-race__solution--title">
                  {t('ratRaceBlock.solution.title')}
                </div>
                <div className="rat-race__solution--name">
                  {t('ratRaceBlock.solution.subtitle')}
                </div>
                <div className="rat-race__solution--description">
                  {t('ratRaceBlock.solution.text1')}
                  {' '}
                  <SVGUsdt />
                  {' '}
                  <b>{t('ratRaceBlock.solution.smartcontract')}</b>
                  <br />
                  {t('ratRaceBlock.solution.text2')}
                  <span>
                    {' '}
                    <SVGBinance />
                    {' '}
                    <b>{t('ratRaceBlock.solution.blockchain')}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rat-race__scroll-down">
          <SVGScrollArrow fill="#F0DFFF" opacity={0.2} />
          <span>{ t('common.scrollDown') }</span>
        </div>
      </div>
      <div className="rat-race-holder__logo">
        <SVGIdeaDao />
      </div>
      <div className="rat-race-holder__new-economy">
        { t('ratRaceBlock.newEconomy') }
      </div>
    </div>
  );
}
