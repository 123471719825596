export default function SVGArrowDown({
  fill = '#FAAE54',
}) {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.2" d="M9.18946 6.09462L13.3355 1.86019C13.4315 1.76226 13.4844 1.63152 13.4844 1.49212C13.4844 1.35272 13.4315 1.22198 13.3355 1.12404L13.0303 0.812207C12.8314 0.609293 12.5081 0.609293 12.3095 0.812207L8.82785 4.36796L5.34237 0.808261C5.2464 0.710324 5.11846 0.65625 4.98204 0.65625C4.84547 0.65625 4.71753 0.710324 4.62148 0.808261L4.3163 1.1201C4.22033 1.21811 4.16746 1.34877 4.16746 1.48817C4.16746 1.62757 4.22033 1.75831 4.3163 1.85625L8.46616 6.09462C8.56243 6.19279 8.69097 6.24671 8.82762 6.2464C8.9648 6.24671 9.09327 6.19279 9.18946 6.09462Z" fill={fill} />
      <path opacity="0.5" d="M9.48791 14.0639L16.9508 7.00657C17.1236 6.84335 17.2187 6.62545 17.2187 6.39311C17.2187 6.16078 17.1236 5.94288 16.9508 5.77965L16.4014 5.25993C16.0433 4.92174 15.4614 4.92174 15.1039 5.25993L8.837 11.1862L2.56313 5.25335C2.39039 5.09012 2.1601 5 1.91455 5C1.66872 5 1.43843 5.09012 1.26555 5.25335L0.716217 5.77308C0.543468 5.93644 0.448303 6.1542 0.448303 6.38654C0.448303 6.61887 0.543468 6.83677 0.716217 7L8.18596 14.0639C8.35925 14.2276 8.59063 14.3174 8.83659 14.3169C9.08351 14.3174 9.31475 14.2276 9.48791 14.0639Z" fill={fill} />
    </svg>
  );
}
