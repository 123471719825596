import { useEffect, useRef } from 'react';

export default function Noise({
  width = null,
  height = null,
  className = '',
}) {
  const canvas = useRef();

  const noise = (cvn) => {
    const noiseData = [];
    const canvas = cvn.current;
    const ctx = canvas.getContext('2d');

    let w;
    let h;
    let frame = 0;
    let loopTimeout;

    const createNoise = () => {
      const idata = ctx.createImageData(w, h);
      const buffer32 = new Uint32Array(idata.data.buffer);
      const len = buffer32.length;
      for (let i = 0; i < len; i++) {
        if (Math.random() < 0.9) {
          buffer32[i] = 4278190080;
        }
      }
      noiseData.push(idata);
    };
    const paintNoise = () => {
      if (frame === 9) {
        frame = 0;
      } else {
        frame++;
      }
      ctx.putImageData(noiseData[frame], 0, 0);
    };
    const loop = () => {
      paintNoise(frame);
      loopTimeout = window.setTimeout(() => {
        window.requestAnimationFrame(loop);
      }, (40));
    };
    const init = (() => {
      w = window.innerWidth;
      h = window.innerHeight;
      canvas.width = w;
      canvas.height = h;
      for (let i = 0; i < 10; i++) {
        createNoise();
      }
      loop();
    })();
  };

  useEffect(() => {
    noise(canvas);
  }, [width, height]);

  return (
    <canvas width="500" height="200" ref={canvas} className={className} />
  );
}
