import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FLAGS_BY_KEY, LANGUAGES_LIST, LOCALES_LOCAL_STORAGE_KEY } from '../../features/constants';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import SVGDropdownArrow from '../../assets/svg/SVGDropdownArrow';
import SVGLanguageArrow from '../../assets/svg/SVGLanguageArrow';

export default function LanguageSelector() {
  const menuRef = useRef();
  const btnRef = useRef();
  const [isLangSelectorOpened, toggleLangSelector] = useState(false);

  useEffect(() => {
    const outCheck = (e) => {
      if (isLangSelectorOpened
        && menuRef.current && !menuRef.current.contains(e.target)
        && btnRef && !btnRef.current.contains(e.target)) {
        toggleLangSelector(false);
      }
    };

    document.addEventListener('mouseup', outCheck);
    return () => document.removeEventListener('mouseup', outCheck);
  }, [isLangSelectorOpened]);

  const { changeLanguage, t } = useCustomTranslation();
  const lang = localStorage?.getItem(LOCALES_LOCAL_STORAGE_KEY).toUpperCase() || 'RU';
  const flags = FLAGS_BY_KEY;
  const languagesList = LANGUAGES_LIST;
  const list = useMemo(
    () => languagesList.map(({ label, letters }, idx) => (
      <li
        onClick={() => {
          changeLanguage(letters);
        }}
        key={`lang-s-${idx}`}
      >
        {letters ? flags[letters?.toUpperCase()] : null}
        <span>
          {label}
        </span>
      </li>
    )),
    [],
  );
  return (
    <div className="language-selector">
      <div
        className="language-selector__current"
        onClick={() => toggleLangSelector(!isLangSelectorOpened)}
        ref={btnRef}
      >
        {flags[lang]}
        <span>
          {lang}
          <SVGDropdownArrow />
        </span>
      </div>
      <AnimatePresence>
        {isLangSelectorOpened && (
          <motion.div
            className="language-selector__dropdown"
            ref={menuRef}
            initial={{ y: '20%', x: '-50%', opacity: 0 }}
            animate={{ y: '0%', x: '-50%', opacity: 1 }}
            exit={{ y: '20%', x: '-50%', opacity: 0 }}
            transition={{ ease: 'backInOut', duration: 0.4 }}
          >
            <SVGLanguageArrow />
            <div className="language-selector__dropdown--title">
              {t('menu.languageSelector')}
            </div>
            <ul className="language-selector__dropdown--list">
              { list }
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
