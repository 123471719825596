export default function SVGTurkeyFlag() {
  return (
    <svg version="1.1" id="svg29881" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 480.4 480.1">
      <g id="layer1" transform="translate(0 -540.362)">
        <path id="path3798" fill="#C0392B" d="M479.5,780.2c0,132.4-107.3,239.8-239.7,239.8S0,912.6,0,780.2s107.3-239.8,239.7-239.8S479.5,647.7,479.5,780.2L479.5,780.2z" />
        <g>
          <defs>
            <path id="SVGID_1_" d="M480.4,780.6c0,132.5-107.5,239.9-240.1,239.9S0.3,913.1,0.3,780.6l0,0c0-132.5,107.5-239.9,240.1-239.9S480.4,648.1,480.4,780.6L480.4,780.6z" />
          </defs>
        </g>
        <g id="g5869" transform="matrix(1.2 0 0 1.2 1566.496 -895.863)">
          <path id="path6120" fill="#FFFFFF" d="M-1115.1,1471.4c-41.1,0-74.3-33.2-74.3-74.3c0-41,33.2-74.3,74.3-74.3c20.5,0,39,8.3,52.4,21.7c-16.6-23.8-44.2-39.3-75.4-39.3c-50.8,0-92,41.2-92,91.9c0,50.8,41.2,91.9,92,91.9c31.2,0,58.8-15.6,75.4-39.3C-1076.1,1463.1-1094.6,1471.4-1115.1,1471.4" />
          <path id="path6122" fill="#FFFFFF" d="M-1026.1,1357.8l17.1,24.7l28.9-8.7l-18.3,24l17.1,24.8l-28.4-10l-18.3,24l0.7-30.1l-28.4-10l28.9-8.6L-1026.1,1357.8z" />
        </g>
      </g>
    </svg>
  );
}
