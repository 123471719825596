import { useEffect, useRef } from 'react';
import target from '../../assets/img/ideology/1.png';
import rocket from '../../assets/img/ideology/2.png';
import ratrun from '../../assets/img/ideology/3.png';
import laptop from '../../assets/img/ideology/4.png';
import iphone from '../../assets/img/ideology/5.png';
import wallet from '../../assets/img/ideology/6.png';
import box from '../../assets/img/ideology/7.png';
import SafeHTML from '../../components/SafeHTML';
import SVGButtonArrow from '../../assets/svg/SVGButtonArrow';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import Button from '../../components/Button';
import SVGIdeaBackground from '../../assets/svg/SVGIdeaBackground';
import { DASHBOARD_LINK } from '../../features/constants';

export default function BlockIdeology() {
  const { t } = useCustomTranslation();
  const trackRef = useRef(null);
  const scrollHolder = useRef();
  const list = [
    {
      id: 1,
      title: t('ideologyBlock.list.element1.title'),
      text: t('ideologyBlock.list.element1.text'),
      picture: wallet,
      customClass: 'ideology__block--b1',
    },
    {
      id: 2,
      title: t('ideologyBlock.list.element2.title'),
      text: t('ideologyBlock.list.element2.text'),
      picture: rocket,
      customClass: 'ideology__block--b2',
    },
    {
      id: 3,
      title: t('ideologyBlock.list.element3.title'),
      text: t('ideologyBlock.list.element3.text'),
      picture: ratrun,
      customClass: 'ideology__block--b3',
    },
    {
      id: 4,
      title: t('ideologyBlock.list.element4.title'),
      text: t('ideologyBlock.list.element4.text'),
      picture: box,
      customClass: 'ideology__block--b4',
    },
    {
      id: 5,
      title: t('ideologyBlock.list.element5.title'),
      text: t('ideologyBlock.list.element5.text'),
      picture: target,
      customClass: 'ideology__block--b5',
    },
  ];

  useEffect(() => {
    scrollHolder.current.style.height = `${trackRef.current.offsetWidth}px`;
    const scrollHandler = () => {
      const containerAbsoluteOffsetTop = scrollHolder.current.getBoundingClientRect().y;
      const offsetLeft = containerAbsoluteOffsetTop < 0 ? containerAbsoluteOffsetTop : 0;

      trackRef.current.style.transform = `translate3d(${offsetLeft}px, 0px, 0px)`;
    };

    window.addEventListener('scroll', scrollHandler, false);
    return () => {
      window.removeEventListener('scroll', scrollHandler, false);
    };
  }, []);

  return (
    <div className="ideology-scroll" ref={scrollHolder} id="ideology">
      <div className="ideology">
        <div className="container">
          <div className="ideology__title">
            <span>
              {t('ideologyBlock.title')}
            </span>
            <b>
              {t('ideologyBlock.subtitle')}
            </b>
          </div>
        </div>
        <div className="ideology__list">
          <ul ref={trackRef} className="ideology__list--track">
            {list.map((element, key) => (
              <li className={`ideology__block ${element?.customClass}`} key={`ideology-key-${element?.id}`}>
                <div className="ideology__block--background">
                  <SVGIdeaBackground />
                </div>
                <div className="ideology__block--number">
                  {element?.id || key + 1}
                </div>
                <div className="ideology__block--desc">
                  <div className="ideology__block--title" data-shadow={element?.title}>
                    <span>{element?.title}</span>
                  </div>
                  <SafeHTML html={element?.text}>
                    <div className="ideology__block--text" />
                  </SafeHTML>
                  <div className="ideology__block--picture">
                    <img src={element?.picture} alt="" />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="container">
          <div className="ideology__footer">
            <a href={`${DASHBOARD_LINK}/reg`} className="default-button ideology__button">
              <span>
                { t('common.connect') }
              </span>
              <i className="icon">
                <SVGButtonArrow />
              </i>
            </a>
            <SafeHTML html={t('common.webReg')}>
              <div className="button-subtitle" />
            </SafeHTML>
          </div>
        </div>
      </div>
    </div>
  );
}
