import { useEffect, useRef, useState } from 'react';
import SVGDevice from '../../assets/svg/SVGDevice';
import SVGForsage from '../../assets/svg/SVGForsage';
import SVGProperty from '../../assets/svg/SVGProperty';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import SVGRocket from '../../assets/svg/SVGRocket';
import SVGButtonArrow from '../../assets/svg/SVGButtonArrow';
import launchPicture from '../../assets/img/programs/launch.png';
import devicePicture from '../../assets/img/programs/device.png';
import forsagePicture from '../../assets/img/programs/forsage.png';
import propertyPicture from '../../assets/img/programs/property.png';
import SVGProgramsVideo from '../../assets/svg/SVGProgramsVideo';
import SafeHTML from '../../components/SafeHTML';
import { getId } from '../../features/helpers';
import VideoModal from '../../components/Modals/VideoModal';

export function LaunchBlock() {
  const { t } = useCustomTranslation();
  return (
    <>
      <div className="programs__block">
        <div className="programs__block--header">
          <SVGRocket />
          Launch
        </div>
        <div className="programs__block--number">
          1
        </div>
        <ul className="programs__block--levels">
          <li>STEP 1</li>
          <li>STEP 2</li>
          <li>STEP 3</li>
          <li>STEP 4</li>
        </ul>
        <div className="programs__block--desc">
          <SafeHTML html={t('programsBlock.programs.launch.description')} />
          <p className="--transparent">
            {t('programsBlock.programs.launch.discount')}
          </p>
        </div>
        <div className="programs__block--result">
          <div className="programs__block--result__title">
            {t('programsBlock.startsFrom')}
          </div>
          <div className="programs__block--result__numbers">
            <div className="programs__block--result__to">
              {t('programsBlock.programs.launch.priceFrom')}
            </div>
          </div>
        </div>
        <div className="programs__block--result__picture --launch">
          <img src={launchPicture} alt="" />
        </div>
      </div>
      <div className="programs__block--footer">
        <button className="default-button programs__block--button">
          { t('common.download') }
          <i className="icon">
            <SVGButtonArrow />
          </i>
        </button>
        <div className="menu-block__bottom--description">
          { t('common.pdf') }
        </div>
      </div>
    </>
  );
}

export function DeviceBlock() {
  const { t } = useCustomTranslation();
  return (
    <>
      <div className="programs__block">
        <div className="programs__block--header">
          <SVGDevice />
          Device
        </div>
        <div className="programs__block--number">
          2
        </div>
        <ul className="programs__block--levels">
          <li>START</li>
          <li>PRO</li>
          <li>PREMIUM</li>
          <li>LUXURY</li>
        </ul>
        <div className="programs__block--desc">
          <SafeHTML html={t('programsBlock.programs.device.description')} />
          <p className="--transparent">
            {t('programsBlock.programs.device.discount')}
          </p>
        </div>
        <div className="programs__block--result">
          <div className="programs__block--result__title">
            {t('programsBlock.startsFrom')}
          </div>
          <div className="programs__block--result__numbers">
            <div className="programs__block--result__to">
              {t('programsBlock.programs.device.priceFrom')}
            </div>
          </div>
        </div>
        <div className="programs__block--result__picture --device">
          <img src={devicePicture} alt="" />
        </div>
      </div>
      <div className="programs__block--footer">
        <button className="default-button programs__block--button">
          { t('common.download') }
          <i className="icon">
            <SVGButtonArrow />
          </i>
        </button>
        <div className="menu-block__bottom--description">
          { t('common.pdf') }
        </div>
      </div>
    </>
  );
}

export function ForsageBlock() {
  const { t } = useCustomTranslation();
  return (
    <>
      <div className="programs__block">
        <div className="programs__block--header">
          <SVGForsage />
          Forsage
        </div>
        <div className="programs__block--number">
          3
        </div>
        <ul className="programs__block--levels">
          <li>START</li>
          <li>PRO</li>
          <li>PREMIUM</li>
          <li>LUXURY</li>
        </ul>
        <div className="programs__block--desc">
          <SafeHTML html={t('programsBlock.programs.forsage.description')} />
          <p className="--transparent">
            {t('programsBlock.programs.forsage.discount')}
          </p>
        </div>
        <div className="programs__block--result">
          <div className="programs__block--result__title">
            {t('programsBlock.startsFrom')}
          </div>
          <div className="programs__block--result__numbers">
            <div className="programs__block--result__to">
              {t('programsBlock.programs.forsage.priceFrom')}
            </div>
          </div>
        </div>
        <div className="programs__block--result__picture --forsage">
          <img src={forsagePicture} alt="" />
        </div>
      </div>
      <div className="programs__block--footer">
        <button className="default-button programs__block--button">
          { t('common.download') }
          <i className="icon">
            <SVGButtonArrow />
          </i>
        </button>
        <div className="menu-block__bottom--description">
          { t('common.pdf') }
        </div>
      </div>
    </>
  );
}

export function PropertyBlock() {
  const { t } = useCustomTranslation();
  return (
    <>
      <div className="programs__block">
        <div className="programs__block--header">
          <SVGProperty />
          Property
        </div>
        <div className="programs__block--number">
          4
        </div>
        <ul className="programs__block--levels">
          <li>START</li>
          <li>PRO</li>
          <li>PREMIUM</li>
          <li>LUXURY</li>
        </ul>
        <div className="programs__block--desc">
          <SafeHTML html={t('programsBlock.programs.property.description')} />
          <p className="--transparent">
            {t('programsBlock.programs.property.discount')}
          </p>
        </div>
        <div className="programs__block--result">
          <div className="programs__block--result__title">
            {t('programsBlock.startsFrom')}
          </div>
          <div className="programs__block--result__numbers">
            <div className="programs__block--result__to">
              {t('programsBlock.programs.property.priceFrom')}
            </div>
          </div>
        </div>
        <div className="programs__block--result__picture --property">
          <img src={propertyPicture} alt="" />
        </div>
      </div>
      <div className="programs__block--footer">
        <button className="default-button programs__block--button">
          { t('common.download') }
          <i className="icon">
            <SVGButtonArrow />
          </i>
        </button>
        <div className="menu-block__bottom--description">
          { t('common.pdf') }
        </div>
      </div>
    </>
  );
}

export default function ProgramsBlock() {
  const { t, lng } = useCustomTranslation();
  const [currentBlock, setCurrentBlock] = useState(0);
  const programs = [
    {
      name: 'Launch',
      icon: <SVGRocket />,
      block: <LaunchBlock />,
      video: t('videos.launch'),
    },
    {
      name: 'Device',
      icon: <SVGDevice />,
      block: <DeviceBlock />,
      video: t('videos.device'),
    },
    {
      name: 'Forsage',
      icon: <SVGForsage />,
      block: <ForsageBlock />,
      video: false,
    },
    {
      name: 'Property',
      icon: <SVGProperty />,
      block: <PropertyBlock />,
      video: false,
    },
  ];
  const [video, setVideo] = useState(programs[0]?.video);
  const [show, toggle] = useState(false);

  useEffect(() => {
    setVideo(programs[currentBlock]?.video);
  }, [lng]);

  return (
    <div className="programs" id="programs">
      <div className="programs__top-title">
        {t('programsBlock.topTitle')}
      </div>
      <div className="programs__holder">
        <div className="container">
          <div className="programs__title">
            <SafeHTML html={t('programsBlock.title')} />
          </div>
          <div className="programs__row">
            <div className="programs__cell">
              <div className="programs__list">
                <div className="programs__list--title">
                  {t('programsBlock.selectProgram')}
                </div>
                <ul>
                  {programs.map((element, key) => (
                    <li
                      key={`program-element-${key.toString()}`}
                      data-hover={element.name}
                      onClick={() => {
                        setCurrentBlock(key);
                        setVideo(element?.video);
                      }}
                      className={`${currentBlock === key ? '--hover' : ''}`}
                    >
                      {element.icon}
                      <span>{element.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="programs__video">
                <div className="programs__video--button" onClick={() => toggle(true)}>
                  <SVGProgramsVideo />
                </div>
                <div className="programs__video--text">
                  <SafeHTML html={t('programsBlock.howItWorks')} />
                </div>
              </div>
            </div>
            <div className="programs__cell">
              {programs.map((element, key) => (
                key === currentBlock && (
                  element.block
                )
              ))}
            </div>
          </div>
        </div>
      </div>
      <VideoModal open={show} onClose={() => toggle(false)} src={video} />
    </div>
  );
}
