import { useEffect, useRef } from 'react';

export default function Matrix({
  width = null,
  height = null,
  className = '',
  font = '15pt monospace',
  color = '#fff',
}) {
  const canvas = useRef();

  let ctx;
  let w;
  let h;
  let cols;
  let ypos;

  // Initialize canvas
  const initCanvas = () => {
    ctx = canvas.current.getContext('2d');

    canvas.current.width = width || document.body.offsetWidth;
    canvas.current.height = height || document.body.offsetHeight;

    w = canvas.current.width;
    h = canvas.current.height;

    cols = Math.floor(w / 20) + 1;
    ypos = Array(cols).fill(0);

    ctx.fillStyle = 'transparent';
    ctx.fillRect(0, 0, w, h);
  };

  // Matrix
  const matrix = () => {
    ctx.fillStyle = '#0001';
    ctx.fillRect(0, 0, w, h);

    ctx.fillStyle = color;
    ctx.font = font;

    ypos.forEach((y, ind) => {
      const text = Math.floor(Math.random() * (1 - 0 + 1) + 0);
      const x = ind * 20;

      ctx.fillText(text, x, y);

      if (y > 100 + Math.random() * 10000) {
        ypos[ind] = 0;
      } else {
        ypos[ind] = y + 20;
      }
    });
  };

  useEffect(() => {
    initCanvas();

    const matrixInterval = setInterval(matrix, 50);
    return () => {
      clearInterval(matrixInterval);
    };
  }, [width, height]);

  return (
    <canvas width="500" height="200" ref={canvas} className={className} />
  );
}
