import { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Matrix from '../Matrix';
import Noise from '../Noise';
import Button from '../Button';
import SVGButtonArrow from '../../assets/svg/SVGButtonArrow';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import SafeHTML from '../SafeHTML';

export function MenuBlockHolder({
  children, show = false,
}) {
  const menuBlock = useRef();

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className="big-menu__block"
          initial={{ x: 0, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 0, opacity: 0 }}
          ref={menuBlock}
          transition={{ duration: 0.1 }}
        >
          <div
            className="big-menu__block--background"
          />
          <div
            className="big-menu__block--content"
          >
            { children }
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export function MenuBlockOurMission() {
  const { t } = useCustomTranslation();
  return (
    <div className="menu-block">
      <div className="menu-block__title">
        <SafeHTML html={t('menu.downloadPresentation')} />
      </div>
      <div className="menu-block__description">
        <div className="menu-block__description--text">
          <SafeHTML html={t('menu.missionPresentation')} />
        </div>
        <div className="menu-block__description--picture">
          <img src={require('../../assets/img/menu/ideology.png')} alt="" />
        </div>
      </div>
      <div className="menu-block__bottom">
        <div className="menu-block__bottom--arrow">
          <SVGButtonArrow />
        </div>
        <a href={t('presentations.ideology')} rel="noreferrer" target="_blank" download className="default-button menu-block__button">
          {t('common.download')}
          <i className="icon">
            <SVGButtonArrow />
          </i>
        </a>
        <div className="menu-block__bottom--description">
          {t('common.pdf')}
        </div>
      </div>
    </div>
  );
}

export function MenuBlockPrograms() {
  const { t } = useCustomTranslation();
  return (
    <div className="menu-block">
      <div className="menu-block__title">
        <SafeHTML html={t('menu.downloadPresentation')} />
      </div>
      <div className="menu-block__description">
        <div className="menu-block__description--text">
          <SafeHTML html={t('menu.programsPresentation')} />
        </div>
        <div className="menu-block__description--picture">
          <img src={require('../../assets/img/menu/programs.png')} alt="" />
        </div>
      </div>
      <div className="menu-block__bottom">
        <div className="menu-block__bottom--arrow">
          <SVGButtonArrow />
        </div>
        <a href={t('presentations.programs')} rel="noreferrer" target="_blank" download className="default-button menu-block__button">
          {t('common.download')}
          <i className="icon">
            <SVGButtonArrow />
          </i>
        </a>
        <div className="menu-block__bottom--description">
          {t('common.pdf')}
        </div>
      </div>
    </div>
  );
}

export function MenuBlockYourMoney() {
  const { t } = useCustomTranslation();
  return (
    <div className="menu-block">
      <div className="menu-block__title">
        <SafeHTML html={t('menu.downloadPresentation')} />
      </div>
      <div className="menu-block__description">
        <div className="menu-block__description--text">
          <SafeHTML html={t('menu.moneyPresentation')} />
        </div>
        <div className="menu-block__description--picture">
          <img src={require('../../assets/img/menu/business.png')} alt="" />
        </div>
      </div>
      <div className="menu-block__bottom">
        <div className="menu-block__bottom--arrow">
          <SVGButtonArrow />
        </div>
        <a href={t('presentations.buisnes')} rel="noreferrer" target="_blank" download className="default-button menu-block__button">
          {t('common.download')}
          <i className="icon">
            <SVGButtonArrow />
          </i>
        </a>
        <div className="menu-block__bottom--description">
          {t('common.pdf')}
        </div>
      </div>
    </div>
  );
}

export default function BigMenu({
  show = false,
  onClose = null,
}) {
  const menu = useRef();
  const { t } = useCustomTranslation();
  const [focusedItem, setFocusedItem] = useState(null);
  // const menuElements = [
  //   {
  //     title: t('menu.items.ourMission'),
  //     link: '',
  //     block: <MenuBlockOurMission />,
  //   },
  //   {
  //     title: t('menu.items.programs'),
  //     link: '',
  //     block: <MenuBlockPrograms />,
  //   },
  //   {
  //     title: t('menu.items.yourMoney'),
  //     link: '',
  //     block: <MenuBlockYourMoney />,
  //   },
  // ];
  const menuElements = [
    {
      title: t('menuElements.ourMission'),
      link: '#mission',
    },
    {
      title: t('menuElements.ourIdiology'),
      link: '#ideology',
    },
    {
      title: t('menuElements.ourTechnologies'),
      link: '#technologies',
    },
    {
      title: t('menuElements.ourPrograms'),
      link: '#programs',
    },
    {
      title: t('menuElements.career'),
      link: '#career',
    },
  ];

  useEffect(() => {
    if (show === true) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      document.body.classList.add('menu-fixed');
    } else {
      document.body.classList.remove('menu-fixed');
      setFocusedItem(null);
    }
  }, [show]);

  return (
    <div className={`big-menu ${show ? 'this-active' : ''}`} ref={menu}>
      <div className="big-menu__overlay-1">
        <div className="big-menu__overlay-2" />
      </div>
      <div className={`big-menu__main ${show ? 'this-active' : ''}`}>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Matrix width={window.outerWidth} height={window.outerHeight} className="big-menu__matrix" font="15pt monospace" />
            <Noise width={window.outerWidth} height={window.outerHeight} className="big-menu__noise" />
          </motion.div>
        </AnimatePresence>
        <div className="big-menu__content">
          <div className="big-menu__wrapper">
            <div className="big-menu__side big-menu__side--left">
              {menuElements.map((element, key) => (
                <a
                  onMouseEnter={() => setFocusedItem(key)}
                  onClick={() => onClose && onClose()}
                  className={`big-menu__element ${focusedItem === key ? '--hover' : ''}`}
                  data-text={element.title}
                  key={`left-key-${key.toString()}`}
                  href={element.link}
                >
                  <span
                    className={`${focusedItem === key ? 'glitch once' : ''}`}
                    data-text={element.title}
                  >
                    { element.title }
                  </span>
                </a>
              ))}
            </div>
            <div className="big-menu__side big-menu__side--right">
              {menuElements.map((element, key) => (
                <MenuBlockHolder key={`right-key-${key.toString()}`} show={focusedItem === key}>
                  { element.block }
                </MenuBlockHolder>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
