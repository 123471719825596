export default function SVGKoreanFlag() {
  return (
    <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M74.6457 37.3227C74.6457 32.4214 73.6803 27.568 71.8047 23.0398C69.929 18.5116 67.1798 14.3972 63.7141 10.9314C60.2483 7.46568 56.1339 4.7165 51.6057 2.84085C47.0775 0.965204 42.2241 -0.000177852 37.3228 -0.000177852C32.4215 -0.000177852 27.5682 0.965204 23.04 2.84085C18.5118 4.7165 14.3974 7.46568 10.9316 10.9314C7.46586 14.3972 4.71669 18.5116 2.84104 23.0398C0.96539 27.568 -1.03287e-07 32.4214 0 37.3227C-1.03287e-07 42.224 0.96539 47.0773 2.84104 51.6055C4.71669 56.1337 7.46586 60.2482 10.9316 63.7139C14.3974 67.1796 18.5118 69.9288 23.04 71.8045C27.5682 73.6801 32.4215 74.6455 37.3228 74.6455C42.2241 74.6455 47.0775 73.6801 51.6057 71.8045C56.1339 69.9288 60.2483 67.1796 63.7141 63.7139C67.1798 60.2482 69.929 56.1337 71.8047 51.6055C73.6803 47.0773 74.6457 42.224 74.6457 37.3227Z" fill="white" />
      <path d="M9.00391 46.0098L16.6536 57.4774ZM11.8708 44.0973L19.5205 55.565ZM14.7377 42.1849L22.3875 53.6526Z" fill="black" />
      <path d="M9.00391 46.0098L16.6536 57.4774M11.8708 44.0973L19.5205 55.565M14.7377 42.1849L22.3875 53.6526" stroke="#222222" strokeWidth="9.01166" />
      <path d="M51.0508 17.9609L58.7005 29.4286ZM53.9177 16.0485L61.5674 27.5162ZM56.7846 14.1361L64.4343 25.6038Z" fill="black" />
      <path d="M51.0508 17.9609L58.7005 29.4286M53.9177 16.0485L61.5674 27.5162M56.7846 14.1361L64.4343 25.6038" stroke="#222222" strokeWidth="9.01166" />
      <path d="M52.9766 24.9766L62.533 18.6018Z" fill="black" />
      <path d="M52.9766 24.9766L62.533 18.6018" stroke="white" strokeWidth="4.43706" />
      <path d="M48.1905 28.1624C52.4154 34.4958 50.706 43.055 44.3726 47.2798C38.0391 51.5046 29.48 49.7952 25.2552 43.4618C21.0304 37.1284 22.7397 28.5692 29.0731 24.3444C35.4066 20.1196 43.9657 21.829 48.1905 28.1624Z" fill="#E74C3C" />
      <path d="M25.2552 43.4618C24.2408 41.9411 23.872 40.0797 24.23 38.2871C24.588 36.4945 25.6435 34.9175 27.1642 33.9031C28.6849 32.8887 30.5463 32.5199 32.3389 32.8779C34.1315 33.2359 35.7084 34.2914 36.7229 35.8121C37.7373 37.3328 39.3142 38.3883 41.1068 38.7463C42.8994 39.1043 44.7608 38.7355 46.2816 37.7211C47.8023 36.7067 48.8577 35.1297 49.2157 33.3371C49.5737 31.5445 49.205 29.6831 48.1905 28.1624C46.1617 25.121 43.0078 23.0101 39.4226 22.2941C35.8374 21.5781 32.1146 22.3156 29.0731 24.3444C26.0317 26.3733 23.9208 29.5272 23.2048 33.1124C22.4888 36.6976 23.2264 40.4204 25.2552 43.4618Z" fill="#356BA0" />
      <path d="M16.6797 14.1406L9.02997 25.6083ZM19.5466 16.0531L11.8969 27.5207ZM22.4135 17.9655L14.7638 29.4332Z" fill="black" />
      <path d="M16.6797 14.1406L9.02997 25.6083M19.5466 16.0531L11.8969 27.5207M22.4135 17.9655L14.7638 29.4332" stroke="#222222" strokeWidth="9.01166" />
      <path d="M58.7266 42.1914L51.0768 53.6591ZM61.5935 44.1038L53.9438 55.5715ZM64.4604 46.0163L56.8107 57.4839Z" fill="black" />
      <path d="M58.7266 42.1914L51.0768 53.6591M61.5935 44.1038L53.9438 55.5715M64.4604 46.0163L56.8107 57.4839" stroke="#222222" strokeWidth="9.01166" />
      <path d="M14.2852 20.8301L17.1521 22.7425ZM52.9886 46.6479L56.3333 48.879ZM59.2002 50.7915L62.0671 52.7039Z" fill="black" />
      <path d="M14.2852 20.8301L17.1521 22.7425M52.9886 46.6479L56.3333 48.879M59.2002 50.7915L62.0671 52.7039" stroke="white" strokeWidth="4.43706" />
    </svg>
  );
}
