import { useRef, useState } from 'react';
import { motion, useScroll } from 'framer-motion';
import Spline from '@splinetool/react-spline';
import SVGPlayPromo from '../../assets/svg/SVGPlayPromo';
import SVGLogo from '../../assets/svg/SVGLogo';
import MacBook from '../../assets/img/macbook.png';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import PromoModal from '../../components/Modals/PromoModal';
import SafeHTML from '../../components/SafeHTML';

export default function PromoBlock({
  scene = true,
}) {
  const block = useRef();
  const [promo, togglePromo] = useState(false);
  const { t } = useCustomTranslation();
  const { scrollYProgress } = useScroll({
    target: block,
    offset: [0.657, 0.660],
  });
  return (
    <div className="promo-block" ref={block} id="promo">
      <div className="promo-block__scene">
        <SafeHTML html={t('promoBlock.title')}>
          <div className="promo-block__title" />
        </SafeHTML>
        <div className="promo-block__logo">
          <SVGLogo />
          <SVGLogo />
        </div>
        <motion.div
          className="promo-block__play-button"
          style={{ opacity: scrollYProgress }}
          onClick={() => togglePromo(true)}
        >
          <SVGPlayPromo />
        </motion.div>
        {scene && (
          <Spline
            className="promo-block__scene--canvas"
            scene="https://prod.spline.design/n9BPcEmdZpJKi0Ox/scene.splinecode"
          />
        )}
        <div className="promo-block__mobile">
          <div className="promo-block__mobile--holder">
            <div className="promo-block__mobile--play" onClick={() => togglePromo(true)}>
              <SVGPlayPromo />
            </div>
            <img src={MacBook} alt="" />
          </div>
        </div>
      </div>
      <PromoModal open={promo} onClose={() => togglePromo(false)} />
    </div>
  );
}
