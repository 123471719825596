import './scss/App.scss';
import { AnimatePresence } from 'framer-motion';
import SVGStroke from './assets/svg/SVGStroke';
import Cursor from './components/Cursor';
import Menu from './components/Menu';
import HeaderBlock from './blocks/HeaderBlock';
import PromoBlock from './blocks/PromoBlock';
import ProgramsBlock from './blocks/ProgramsBlock';
import BlockchainBlock from './blocks/BlockchainBlock';
import RatRaceBlock from './blocks/RatRaceBlock';
import BlockIdeology from './blocks/BlockIdiology';
import BlockSteps from './blocks/BlockSteps';
import BlockConsumerFund from './blocks/BlockConsumerFund';
import BlockSmartContract from './blocks/BlockSmartContract';
import BlockMoneyParadox from './blocks/BlockMoneyParadox';
import ConsumerPatternBlock from './blocks/ConsumerPatternBlock';
import BlockCareer from './blocks/BlockCareer';
import BlockPartnerProgram from './blocks/BlockPartnerProgram';
import FooterBlock from './blocks/FooterBlock';

function App() {
  const macOsClass = '--mac';
  if (navigator.platform.match('Mac') !== null || navigator.platform.match('iPhone')) {
    if (!document.body.classList.contains(macOsClass)) {
      document.body.classList.add(macOsClass);
    }
  }
  return (
    <AnimatePresence mode="wait">
      {/* <Cursor /> */}
      <SVGStroke radius={1} />
      <Menu />
      <HeaderBlock />
      <PromoBlock />
      <RatRaceBlock />
      <BlockIdeology />
      <BlockSteps />
      <BlockConsumerFund />
      <BlockchainBlock />
      <BlockSmartContract />
      <ProgramsBlock />
      <ConsumerPatternBlock />
      <BlockMoneyParadox />
      <BlockCareer />
      {/* <BlockPartnerProgram /> */}
      <FooterBlock />
    </AnimatePresence>
  );
}

export default App;
