export default function SVGBlockchain() {
  return (
    <svg width="59" height="57" viewBox="0 0 59 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.5061 7.24399C32.5061 6.96949 32.397 6.70623 32.2029 6.51213C32.0088 6.31803 31.7456 6.20898 31.4711 6.20898H27.3311V8.27899H31.4711C31.7456 8.27899 32.0088 8.16995 32.2029 7.97585C32.397 7.78175 32.5061 7.51849 32.5061 7.24399Z" fill="#F0DFFF" />
      <path d="M32.5061 11.3856C32.5061 11.1111 32.397 10.8478 32.2029 10.6537C32.0088 10.4596 31.7456 10.3506 31.4711 10.3506H27.3311V12.4206H31.4711C31.7456 12.4206 32.0088 12.3116 32.2029 12.1174C32.397 11.9233 32.5061 11.6601 32.5061 11.3856Z" fill="#F0DFFF" />
      <path d="M29.4049 18.6296C31.2472 18.6296 33.0482 18.0833 34.58 17.0597C36.1119 16.0362 37.3058 14.5814 38.0109 12.8793C38.7159 11.1772 38.9004 9.30422 38.5409 7.49728C38.1815 5.69034 37.2943 4.03056 35.9916 2.72783C34.6889 1.4251 33.0291 0.537925 31.2222 0.178502C29.4152 -0.180921 27.5423 0.00354773 25.8402 0.708581C24.1381 1.41361 22.6833 2.60755 21.6597 4.1394C20.6362 5.67125 20.0898 7.47222 20.0898 9.31456C20.0937 11.7839 21.0763 14.151 22.8224 15.8971C24.5685 17.6431 26.9356 18.6258 29.4049 18.6296ZM24.2299 12.4196H25.2649V6.20954H24.2299V4.13953H26.2999V3.10453H28.3699V4.13953H30.4399V3.10453H32.5099V4.32584C33.113 4.54066 33.6353 4.93611 34.0057 5.45836C34.3761 5.98061 34.5766 6.6043 34.5799 7.24455C34.5779 8.011 34.29 8.74913 33.7726 9.31456C34.29 9.87999 34.5779 10.6181 34.5799 11.3846C34.5766 12.0248 34.3761 12.6485 34.0057 13.1708C33.6353 13.693 33.113 14.0884 32.5099 14.3033V15.5246H30.4399V14.4896H28.3699V15.5246H26.2999V14.4896H24.2299V12.4196Z" fill="#F0DFFF" />
      <path d="M49.0703 37.2588H55.2803V38.2938H49.0703V37.2588Z" fill="#F0DFFF" />
      <path d="M49.0703 40.3643H55.2803V41.3993H49.0703V40.3643Z" fill="#F0DFFF" />
      <path d="M45.9561 34.1548V56.9249H58.3761V34.1548H45.9561ZM52.1661 53.8199C51.7567 53.8199 51.3565 53.6985 51.016 53.471C50.6756 53.2436 50.4103 52.9203 50.2536 52.542C50.097 52.1638 50.056 51.7476 50.1358 51.346C50.2157 50.9445 50.4129 50.5756 50.7024 50.2861C50.9919 49.9966 51.3607 49.7995 51.7622 49.7196C52.1638 49.6398 52.58 49.6807 52.9582 49.8374C53.3365 49.9941 53.6598 50.2594 53.8872 50.5998C54.1147 50.9402 54.2361 51.3405 54.2361 51.7499C54.2361 52.2989 54.018 52.8254 53.6298 53.2136C53.2416 53.6018 52.7151 53.8199 52.1661 53.8199ZM57.3411 45.5398C57.3411 45.8143 57.2321 46.0776 57.038 46.2717C56.8439 46.4658 56.5806 46.5748 56.3061 46.5748H48.0261C47.7516 46.5748 47.4883 46.4658 47.2942 46.2717C47.1001 46.0776 46.9911 45.8143 46.9911 45.5398V36.2248C46.9911 35.9503 47.1001 35.687 47.2942 35.4929C47.4883 35.2988 47.7516 35.1898 48.0261 35.1898H56.3061C56.5806 35.1898 56.8439 35.2988 57.038 35.4929C57.2321 35.687 57.3411 35.9503 57.3411 36.2248V45.5398Z" fill="#F0DFFF" />
      <path d="M49.0703 43.4702H55.2803V44.5052H49.0703V43.4702Z" fill="#F0DFFF" />
      <path d="M26.3037 37.2588H32.5137V38.2938H26.3037V37.2588Z" fill="#F0DFFF" />
      <path d="M26.3037 40.3643H32.5137V41.3993H26.3037V40.3643Z" fill="#F0DFFF" />
      <path d="M23.1885 56.9249H35.6085V34.1548H23.1885V56.9249ZM29.3985 53.8199C28.9891 53.8199 28.5889 53.6985 28.2485 53.471C27.9081 53.2436 27.6427 52.9203 27.4861 52.542C27.3294 52.1638 27.2884 51.7476 27.3683 51.346C27.4481 50.9445 27.6453 50.5756 27.9348 50.2861C28.2243 49.9966 28.5931 49.7995 28.9947 49.7196C29.3962 49.6398 29.8124 49.6807 30.1907 49.8374C30.5689 49.9941 30.8922 50.2594 31.1197 50.5998C31.3471 50.9402 31.4685 51.3405 31.4685 51.7499C31.4685 52.2989 31.2504 52.8254 30.8622 53.2136C30.474 53.6018 29.9475 53.8199 29.3985 53.8199ZM24.2235 36.2248C24.2235 35.9503 24.3325 35.687 24.5266 35.4929C24.7207 35.2988 24.984 35.1898 25.2585 35.1898H33.5385C33.813 35.1898 34.0763 35.2988 34.2704 35.4929C34.4645 35.687 34.5735 35.9503 34.5735 36.2248V45.5398C34.5735 45.8143 34.4645 46.0776 34.2704 46.2717C34.0763 46.4658 33.813 46.5748 33.5385 46.5748H25.2585C24.984 46.5748 24.7207 46.4658 24.5266 46.2717C24.3325 46.0776 24.2235 45.8143 24.2235 45.5398V36.2248Z" fill="#F0DFFF" />
      <path d="M26.3037 43.4702H32.5137V44.5052H26.3037V43.4702Z" fill="#F0DFFF" />
      <path d="M3.52051 40.3643H9.73054V41.3993H3.52051V40.3643Z" fill="#F0DFFF" />
      <path d="M3.52051 37.2588H9.73054V38.2938H3.52051V37.2588Z" fill="#F0DFFF" />
      <path d="M0.421875 56.9249H12.8419V34.1548H0.421875V56.9249ZM6.6319 53.8199C6.22249 53.8199 5.82228 53.6985 5.48187 53.471C5.14146 53.2436 4.87614 52.9203 4.71946 52.542C4.56279 52.1638 4.5218 51.7476 4.60167 51.346C4.68154 50.9445 4.87869 50.5756 5.16819 50.2861C5.45768 49.9966 5.82652 49.7995 6.22806 49.7196C6.62961 49.6398 7.04582 49.6807 7.42406 49.8374C7.80231 49.9941 8.1256 50.2594 8.35305 50.5998C8.58051 50.9402 8.70191 51.3405 8.70191 51.7499C8.70191 52.2989 8.48382 52.8254 8.09562 53.2136C7.70742 53.6018 7.1809 53.8199 6.6319 53.8199ZM1.45688 36.2248C1.45688 35.9503 1.56592 35.687 1.76003 35.4929C1.95413 35.2988 2.21738 35.1898 2.49188 35.1898H10.7719C11.0464 35.1898 11.3097 35.2988 11.5038 35.4929C11.6979 35.687 11.8069 35.9503 11.8069 36.2248V45.5398C11.8069 45.8143 11.6979 46.0776 11.5038 46.2717C11.3097 46.4658 11.0464 46.5748 10.7719 46.5748H2.49188C2.21738 46.5748 1.95413 46.4658 1.76003 46.2717C1.56592 46.0776 1.45688 45.8143 1.45688 45.5398V36.2248Z" fill="#F0DFFF" />
      <path d="M3.52051 43.4702H9.73054V44.5052H3.52051V43.4702Z" fill="#F0DFFF" />
      <path d="M7.66962 26.9096H26.4912C26.6464 27.3419 26.8953 27.7344 27.2201 28.0592C27.5449 28.384 27.9374 28.6329 28.3697 28.7881V33.1196H30.4397V28.7881C30.872 28.6329 31.2646 28.384 31.5893 28.0592C31.9141 27.7344 32.163 27.3419 32.3183 26.9096H52.1748V33.1196H54.2448V25.8746C54.2448 25.6001 54.1358 25.3368 53.9417 25.1427C53.7476 24.9486 53.4843 24.8396 53.2098 24.8396H32.3183C32.163 24.4073 31.9141 24.0147 31.5893 23.69C31.2646 23.3652 30.872 23.1163 30.4397 22.961V19.6646H28.3697V22.961C27.9374 23.1163 27.5449 23.3652 27.2201 23.69C26.8953 24.0147 26.6464 24.4073 26.4912 24.8396H6.63461C6.36011 24.8396 6.09686 24.9486 5.90276 25.1427C5.70865 25.3368 5.59961 25.6001 5.59961 25.8746V33.1196H7.66962V26.9096Z" fill="#F0DFFF" />
    </svg>
  );
}
