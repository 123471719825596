import Slider from 'react-slick';
import SVGCareerPlus from '../../assets/svg/SVGCareerPlus';
import SVGDirectors from '../../assets/svg/SVGDirectors';
import SVGVotes from '../../assets/svg/SVGVotes';
import SVGSmartContract from '../../assets/svg/tech/SVGSmartContract';
import SafeHTML from '../../components/SafeHTML';
import SliderArrowNext from '../../components/SliderArrowNext';
import SliderArrowPrev from '../../components/SliderArrowPrev';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import { DASHBOARD_LINK } from '../../features/constants';
import SVGButtonArrow from '../../assets/svg/SVGButtonArrow';

export default function BlockCareer() {
  const { t } = useCustomTranslation();
  const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
    responsive: [
      {
        breakpoint: 999999,
        settings: 'unslick',
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="career-block" id="career">
      <div className="career-block__bg" />
      <div className="container">
        <div className="career-block__top-title">
          <SafeHTML html={t('careerBlock.topTitle')}>
            <span />
          </SafeHTML>
        </div>
        {/* <div className="career-block__grid"> */}
        <div className="career-block__grid--main">
          <div className="career-block__title">
            {t('careerBlock.title1')}
          </div>
          <div className="career-block__text">
            <SVGCareerPlus />
            <SafeHTML html={t('careerBlock.text')} />
          </div>
          {/* <div className="career-block__title">
            {t('careerBlock.title2')}
          </div> */}
        </div>
        {/* <div className="career-block__grid--stats">
          <div className="career-block__stat-box">
            <div className="career-block__stat-box--title">
              {t('careerBlock.stats.bonuses')}
            </div>
            <div className="career-block__stat-box--num">
              15%
            </div>
          </div>
          <div className="career-block__stat-box">
            <div className="career-block__stat-box--title">
              {t('careerBlock.stats.start')}
            </div>
            <div className="career-block__stat-box--num">
              $25
            </div>
          </div>
          <div className="career-block__stat-box">
            <div className="career-block__stat-box--title">
              {t('careerBlock.stats.webGets')}
            </div>
            <div className="career-block__stat-box--num">
              95%
            </div>
          </div>
        </div> */}
        {/* <div className="career-block__grid--footer">
          <Slider {...sliderSettings} className="career-block__box-grid">
            <div className="career-block__box-grid--wrapper --first">
              <div className="career-block__box">
                <div className="career-block__box--inset">
                  <div className="career-block__box--icon">
                    <SVGSmartContract />
                  </div>
                  <div className="career-block__box--title">
                    <SafeHTML html={t('careerBlock.list.element1.title')} />
                  </div>
                  <div className="career-block__box--text">
                    <SafeHTML html={t('careerBlock.list.element1.text')} />
                  </div>
                </div>
              </div>
            </div>
            <div className="career-block__box-grid--wrapper">
              <div className="career-block__box">
                <div className="career-block__box--inset">
                  <div className="career-block__box--icon">
                    <SVGVotes />
                  </div>
                  <div className="career-block__box--title">
                    <SafeHTML html={t('careerBlock.list.element2.title')} />
                  </div>
                  <div className="career-block__box--text">
                    <SafeHTML html={t('careerBlock.list.element2.text')} />
                  </div>
                </div>
              </div>
            </div>
            <div className="career-block__box-grid--wrapper">
              <div className="career-block__box">
                <div className="career-block__box--inset">
                  <div className="career-block__box--icon">
                    <SVGDirectors />
                  </div>
                  <div className="career-block__box--title">
                    <SafeHTML html={t('careerBlock.list.element3.title')} />
                  </div>
                  <div className="career-block__box--text">
                    <SafeHTML html={t('careerBlock.list.element3.text')} />
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div> */}
        {/* </div> */}
        <div className="num-boxes">
          <div className="num-boxes__block">
            <div className="num-boxes__num">
              1
            </div>
            <div className="num-boxes__title">
              {t('careerBlock.boxes.first.title')}
            </div>
            <div className="num-boxes__text">
              <SafeHTML html={t('careerBlock.boxes.first.text')} />
            </div>
          </div>
          <div className="num-boxes__block">
            <div className="num-boxes__num">
              2
            </div>
            <div className="num-boxes__title">
              {t('careerBlock.boxes.second.title')}
            </div>
            <div className="num-boxes__text">
              <SafeHTML html={t('careerBlock.boxes.second.text')} />
            </div>
          </div>
          <div className="num-boxes__block">
            <div className="num-boxes__num">
              3
            </div>
            <div className="num-boxes__title">
              {t('careerBlock.boxes.third.title')}
            </div>
            <div className="num-boxes__text">
              <SafeHTML html={t('careerBlock.boxes.third.text')} />
            </div>
          </div>
        </div>
        <div className="career-block__footer">
          <a href={`${DASHBOARD_LINK}/reg`} className="orange-button">
            {t('common.connect')}
            <i className="icon">
              <SVGButtonArrow />
            </i>
          </a>
          <SafeHTML html={t('common.webReg')}>
            <div className="career-block__footer--text" />
          </SafeHTML>
        </div>
      </div>
    </div>
  );
}
