export default function SVGSmartChain({
  fill = null,
}) {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.7242 15.8722V17.9566L7.90683 19.0246L6.14115 17.9566V15.8722L7.90683 16.9402L9.7242 15.8722ZM0 8.66302L1.76569 9.73104V13.3055L4.80612 15.1228V17.2072L0 14.3735V8.66302ZM15.8137 8.66302V14.3821L10.9559 17.2158V15.1315L13.9963 13.3141V9.73104L15.8137 8.66302ZM10.9473 5.82931L12.7646 6.89733V8.9817L9.7242 10.7991V14.4338L7.95851 15.5018L6.19283 14.4338V10.7991L3.04043 8.9817V6.89733L4.85779 5.82931L7.89822 7.64667L10.9473 5.82931ZM3.04904 10.4804L4.81473 11.5484V13.6328L3.04904 12.5648V10.4804ZM12.7646 10.4804V12.5648L10.9989 13.6328V11.5484L12.7646 10.4804ZM1.76569 4.01194L3.58305 5.07997L1.76569 6.14799V8.23236L0 7.16434V5.07997L1.76569 4.01194ZM14.048 4.01194L15.8653 5.07997V7.16434L14.048 8.23236V6.14799L12.2823 5.07997L14.048 4.01194ZM7.90683 4.01194L9.7242 5.07997L7.90683 6.1566L6.14115 5.08858L7.90683 4.01194ZM7.90683 0.4375L12.7646 3.27121L10.9989 4.33924L7.95851 2.52187L4.85779 4.33924L3.10072 3.27121L7.90683 0.4375Z" fill={fill || 'url(#paint0_linear_1563_45156)'} />
      <defs>
        <linearGradient id="paint0_linear_1563_45156" x1="7.89742" y1="21.5029" x2="7.89742" y2="2.50273" gradientUnits="userSpaceOnUse">
          <stop stopColor="#77509D" />
          <stop offset="1" stopColor="#B66DFD" />
        </linearGradient>
      </defs>
    </svg>
  );
}
