export default function SVGJudge() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2144_52673)">
        <path d="M14.8348 20.7394C14.8348 20.1568 14.3625 19.6845 13.7799 19.6845H13.4115L9.79873 11.5293C9.10526 11.7117 8.40664 11.8714 7.70402 12.0096L11.104 19.6845H3.79729L7.15126 12.1136C6.33478 12.2601 5.51338 12.3774 4.68846 12.4641L1.48977 19.6845H1.12134C0.53876 19.6845 0.0664534 20.1568 0.0664534 20.7394C0.0508411 24.3648 3.91818 26.798 7.45064 26.717C10.9824 26.7989 14.8515 24.3638 14.8348 20.7394Z" fill="#77509D" />
        <path d="M34.8776 19.6845H34.5092L31.3105 12.4641C30.4856 12.3774 29.6642 12.2601 28.8477 12.1136L32.2017 19.6845H24.895L28.2949 12.0096C27.5923 11.8715 26.8936 11.7117 26.2002 11.5293L22.5874 19.6845H22.2189C21.6364 19.6845 21.1641 20.1568 21.1641 20.7394C21.1641 22.3871 21.967 23.919 23.4251 25.053C26.1444 27.2563 30.9521 27.2564 33.6713 25.053C35.1294 23.919 35.9324 22.3871 35.9324 20.7394C35.9325 20.1568 35.4602 19.6845 34.8776 19.6845Z" fill="#77509D" />
        <path d="M1.05194 10.5419C3.94696 10.5419 6.82932 10.149 9.61899 9.37426C15.0556 7.86402 20.8028 7.86402 26.2394 9.37426C29.029 10.1491 31.9113 10.5419 34.8063 10.5419C36.0029 10.5419 36.2751 8.86482 35.1399 8.4864C32.4019 7.57372 29.7343 6.40034 27.2114 4.99868L25.5448 4.07284C23.5289 2.95291 21.28 2.29136 18.984 2.13558V1.04821C18.9321 -0.349931 16.9256 -0.348876 16.8742 1.04821V2.13558C14.5781 2.29136 12.3293 2.95291 10.3134 4.07284L8.64667 4.99868C6.12382 6.40034 3.45637 7.57372 0.718312 8.4864C-0.416742 8.86482 -0.144653 10.5419 1.05194 10.5419Z" fill="#77509D" />
        <path d="M22.2209 31.7803H13.7818C12.034 31.7803 10.6172 33.1971 10.6172 34.9449C10.6172 35.5275 11.0895 35.9998 11.6721 35.9998H24.3307C24.9132 35.9998 25.3856 35.5275 25.3856 34.9449C25.3856 33.1971 23.9687 31.7803 22.2209 31.7803Z" fill="#77509D" />
        <path d="M16.9453 29.6708H19.0551V10.3712C18.3526 10.345 17.6478 10.345 16.9453 10.3712V29.6708Z" fill="#77509D" />
      </g>
      <defs>
        <clipPath id="clip0_2144_52673">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
