export default function SVGIdeaDao() {
  return (
    <svg width="187" height="155" viewBox="0 0 187 155" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.405213 75.0717C0.798987 54.9158 8.9816 35.7378 23.153 21.7568C37.3244 7.77585 56.3239 0.137089 75.9715 0.520931C95.6191 0.904774 114.305 9.27977 127.92 23.8036C141.534 38.3274 148.962 57.8104 148.568 77.9663L147.863 114.031L186.101 154.727L72.91 152.515C53.2868 152.082 34.6384 143.688 21.0489 129.173C7.45932 114.658 0.036176 95.2041 0.405213 75.0717ZM117.55 77.3603C117.721 68.6086 115.356 60.0043 110.755 52.6375C106.153 45.2707 99.5229 39.4728 91.703 35.9783C83.8832 32.4838 75.2258 31.4499 66.8277 33.0077C58.4297 34.5654 50.6689 38.6446 44.5283 44.7286C38.3877 50.8126 34.1437 58.6275 32.3339 67.1836C30.5242 75.7396 31.2298 84.6517 34.362 92.7908C37.4942 100.93 42.9119 107.93 49.9284 112.904C56.9449 117.878 65.2445 120.601 73.7761 120.731C85.2003 120.946 96.2449 116.498 104.482 108.366C112.719 100.234 117.474 89.0828 117.703 77.3633L117.55 77.3603Z" fill="url(#paint0_linear_1931_46047)" />
      <defs>
        <linearGradient id="paint0_linear_1931_46047" x1="-24.5456" y1="74.2464" x2="166.794" y2="77.9844" gradientUnits="userSpaceOnUse">
          <stop stopColor="#77509D" />
          <stop offset="1" stopColor="#B66DFD" />
        </linearGradient>
      </defs>
    </svg>
  );
}
