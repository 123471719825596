import Slider from 'react-slick';
import SVGPlus from '../../assets/svg/SVGPlus';
import SVGEye from '../../assets/svg/smartcontract/SVGEye';
import SVGLineBig from '../../assets/svg/SVGLineBig';
import SVGHandshake from '../../assets/svg/smartcontract/SVGHandshake';
import SVGInfinite from '../../assets/svg/smartcontract/SVGInfinite';
import SVGJudge from '../../assets/svg/smartcontract/SVGJudge';
import SVGProfit from '../../assets/svg/smartcontract/SVGProfit';
import SVGGlobe from '../../assets/svg/smartcontract/SVGGlobe';
import PictureTV from '../../assets/img/tv.png';
import SafeHTML from '../../components/SafeHTML';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import SVGButtonArrow from '../../assets/svg/SVGButtonArrow';
import SliderArrowNext from '../../components/SliderArrowNext';
import SliderArrowPrev from '../../components/SliderArrowPrev';
import { DASHBOARD_LINK } from '../../features/constants';

export default function BlockSmartContract() {
  const { t } = useCustomTranslation();
  const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
    responsive: [
      {
        breakpoint: 999999,
        settings: 'unslick',
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="smartcontract">
      <div className="smartcontract__plus smartcontract__plus--first">
        <SVGPlus fill="rgba(0, 0, 0, 0.05)" />
        <SVGPlus stroke="#A863ED" />
      </div>
      <div className="smartcontract__plus smartcontract__plus--second">
        <SVGPlus fill="rgba(0, 0, 0, 0.05)" />
        <SVGPlus stroke="#A863ED" />
      </div>
      <div className="smartcontract__plus smartcontract__plus--thrid">
        <SVGPlus fill="rgba(0, 0, 0, 0.05)" />
        <SVGPlus stroke="#A863ED" />
      </div>
      <div className="container">
        <div className="smartcontract__title">
          <span>
            {t('smartContractBlock.title')}
          </span>
          <SVGLineBig />
          <div className="smartcontract__title--picture">
            <img src={PictureTV} alt="" />
          </div>
        </div>
        <SafeHTML html={t('smartContractBlock.description')}>
          <div className="smartcontract__subtitle" />
        </SafeHTML>

        <Slider {...sliderSettings} className="smartcontract__grid">
          <div className="smartcontract__box">
            <div className="smartcontract__box--inset">
              <div className="smartcontract__box--icon">
                <SVGEye />
                <span>
                  <SVGEye />
                </span>
              </div>
              <SafeHTML html={t('smartContractBlock.list.element1.title')}>
                <div className="smartcontract__box--title" />
              </SafeHTML>
              <SafeHTML html={t('smartContractBlock.list.element1.text')}>
                <div className="smartcontract__box--text" />
              </SafeHTML>
            </div>
          </div>
          <div className="smartcontract__box">
            <div className="smartcontract__box--inset">
              <div className="smartcontract__box--icon">
                <SVGHandshake />
                <span>
                  <SVGHandshake />
                </span>
              </div>
              <SafeHTML html={t('smartContractBlock.list.element2.title')}>
                <div className="smartcontract__box--title" />
              </SafeHTML>
              <SafeHTML html={t('smartContractBlock.list.element2.text')}>
                <div className="smartcontract__box--text" />
              </SafeHTML>
            </div>
          </div>
          <div className="smartcontract__box">
            <div className="smartcontract__box--inset">
              <div className="smartcontract__box--icon">
                <SVGInfinite />
                <span>
                  <SVGInfinite />
                </span>
              </div>
              <SafeHTML html={t('smartContractBlock.list.element3.title')}>
                <div className="smartcontract__box--title" />
              </SafeHTML>
              <SafeHTML html={t('smartContractBlock.list.element3.text')}>
                <div className="smartcontract__box--text" />
              </SafeHTML>
            </div>
          </div>
          <div className="smartcontract__box">
            <div className="smartcontract__box--inset">
              <div className="smartcontract__box--icon">
                <SVGJudge />
                <span>
                  <SVGJudge />
                </span>
              </div>
              <SafeHTML html={t('smartContractBlock.list.element4.title')}>
                <div className="smartcontract__box--title" />
              </SafeHTML>
              <SafeHTML html={t('smartContractBlock.list.element4.text')}>
                <div className="smartcontract__box--text" />
              </SafeHTML>
            </div>
          </div>
          <div className="smartcontract__box">
            <div className="smartcontract__box--inset">
              <div className="smartcontract__box--icon">
                <SVGProfit />
                <span>
                  <SVGProfit />
                </span>
              </div>
              <SafeHTML html={t('smartContractBlock.list.element5.title')}>
                <div className="smartcontract__box--title" />
              </SafeHTML>
              <SafeHTML html={t('smartContractBlock.list.element5.text')}>
                <div className="smartcontract__box--text" />
              </SafeHTML>
            </div>
          </div>
          <div className="smartcontract__box">
            <div className="smartcontract__box--inset">
              <div className="smartcontract__box--icon">
                <SVGGlobe />
                <span>
                  <SVGGlobe />
                </span>
              </div>
              <SafeHTML html={t('smartContractBlock.list.element6.title')}>
                <div className="smartcontract__box--title" />
              </SafeHTML>
              <SafeHTML html={t('smartContractBlock.list.element6.text')}>
                <div className="smartcontract__box--text" />
              </SafeHTML>
            </div>
          </div>
        </Slider>
        <div className="smartcontract__footer">
          <a href={`${DASHBOARD_LINK}/reg`} className="orange-button">
            {t('common.connect')}
            <i className="icon">
              <SVGButtonArrow />
            </i>
          </a>
          <SafeHTML html={t('common.webReg')}>
            <div className="smartcontract__footer--text" />
          </SafeHTML>
        </div>
      </div>
    </div>
  );
}
