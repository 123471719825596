export default function SVGUsdt() {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.9">
        <path d="M15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93959 24.0604 0 15.5 0C6.93959 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31Z" fill="#9871BD" />
        <path d="M15.4958 28.9331C22.9149 28.9331 28.9292 22.9188 28.9292 15.4997C28.9292 8.08071 22.9149 2.06641 15.4958 2.06641C8.07681 2.06641 2.0625 8.08071 2.0625 15.4997C2.0625 22.9188 8.07681 28.9331 15.4958 28.9331Z" fill="#77509D" />
        <path d="M17.3391 14.044V12.1238H21.5674V9.09375H9.8305V12.1238H14.0706V14.044C10.5184 14.2206 7.85156 14.9573 7.85156 15.8399C7.85156 16.7226 10.5154 17.4547 14.0706 17.6359V23.9738H17.3391V17.6359C20.8914 17.4609 23.5582 16.7241 23.5582 15.8399C23.5582 14.9558 20.8944 14.2206 17.3391 14.044ZM15.7049 17.0618C11.8585 17.0618 8.74 16.4478 8.74 15.7079C8.74 15.0709 11.0116 14.5368 14.0677 14.394V16.5629C14.5936 16.5875 15.1403 16.6013 15.7019 16.6013C16.2635 16.6013 16.8132 16.5875 17.3362 16.5629V14.394C20.3922 14.5368 22.6638 15.0709 22.6638 15.7079C22.6698 16.4555 19.5513 17.0618 15.7049 17.0618Z" fill="#F1F2F2" />
      </g>
    </svg>
  );
}
