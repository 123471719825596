import { motion, useScroll } from 'framer-motion';
import { useRef } from 'react';
import Spline from '@splinetool/react-spline';
import { useScrollData } from '../../features/hooks/useScrollData';
import Particles from '../../components/Particles';
import SVGArrowDown from '../../assets/svg/SVGArrowDown';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';

export default function BlockMoneyParadox({
  scene = true, title = true,
}) {
  const { t } = useCustomTranslation();
  const block = useRef();
  const { scrolling, direction } = useScrollData();
  const { scrollYProgress } = useScroll({
    target: block,
    offset: [0.20, 0.15],
  });

  return (
    <div className={`money-paradox ${scrolling && direction.y === 'up' ? 'particles-animate-up' : ''} ${scrolling && direction.y === 'down' ? 'particles-animate-down' : ''}`} ref={block}>
      {/* <div className="money-paradox__scroll-down">
        <SVGArrowDown />
      </div> */}
      <Particles count={10} />
      <div className="money-paradox__scene">
        {title && (
          <motion.div
            className="money-paradox__title"
            style={{ opacity: scrollYProgress }}
          >
            <div className="money-paradox__title--shadow --first">
              {t('moneyParadoxBlock.title')}
            </div>
            <div className="money-paradox__title--shadow --second">
              {t('moneyParadoxBlock.title')}
            </div>
            <div className="money-paradox__title--top">
              {t('moneyParadoxBlock.title')}
            </div>
            <div className={`money-paradox__title--main ${t('classPrefix')}title`}>
              {t('moneyParadoxBlock.subTitle')}
              <br />
              {/* <span data-shadow="our programs">
                our programs
              </span> */}
            </div>
          </motion.div>
        )}
        {scene && (
          <Spline
            className="money-paradox__scene--canvas"
            scene="https://prod.spline.design/IEbjsjtwpclub8Sm/scene.splinecode"
          />
        )}
      </div>
    </div>
  );
}
