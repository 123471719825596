export default function SVGGlobe() {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2144_52926)">
        <path d="M4.39616 25.1545C2.83196 22.7473 1.99219 19.9384 1.99219 17C1.99219 12.9913 3.55327 9.22243 6.38788 6.38788C9.2225 3.55327 12.9913 1.99219 17 1.99219C19.9384 1.99219 22.7473 2.83196 25.1545 4.39623C25.4584 3.78144 25.9294 3.2638 26.508 2.90262C23.7222 1.01529 20.4393 0 17 0C12.4591 0 8.19008 1.76833 4.97921 4.97914C1.76833 8.19008 0 12.4591 0 17C0 20.4393 1.01522 23.7222 2.90255 26.508C3.26373 25.9294 3.78137 25.4583 4.39616 25.1545Z" fill="#77509D" />
        <path d="M31.4739 8.0752C31.0906 8.63746 30.5548 9.08743 29.9269 9.36666C31.2827 11.654 32.0067 14.2712 32.0067 16.9999C32.0067 21.0086 30.4456 24.7774 27.611 27.612C24.7764 30.4466 21.0076 32.0077 16.9989 32.0077C14.2702 32.0077 11.6531 31.2837 9.36569 29.9278C9.08645 30.5558 8.63648 31.0915 8.07422 31.4749C10.7303 33.1194 13.7967 33.9999 16.9989 33.9999C21.5397 33.9999 25.8088 32.2315 29.0197 29.0207C32.2306 25.8098 33.9989 21.5407 33.9989 16.9999C33.9989 13.7977 33.1184 10.7313 31.4739 8.0752Z" fill="#77509D" />
        <path d="M26.7695 6.01448C26.7695 6.93786 27.5207 7.68911 28.4442 7.68911C29.3676 7.68911 30.1189 6.93786 30.1189 6.01448C30.1189 5.0911 29.3676 4.33984 28.4442 4.33984C27.5207 4.33984 26.7695 5.0911 26.7695 6.01448Z" fill="#77509D" />
        <path d="M7.68918 28.4442C7.68918 27.5208 6.93792 26.7695 6.01448 26.7695C5.09103 26.7695 4.33984 27.5208 4.33984 28.4442C4.33984 29.3675 5.0911 30.1188 6.01448 30.1188C6.93786 30.1188 7.68918 29.3675 7.68918 28.4442Z" fill="#77509D" />
        <path d="M11.8021 25.8249C11.1335 24.2213 10.6668 22.2823 10.432 20.1829C9.60819 19.7107 8.98463 18.9285 8.721 17.9961H4.64844C4.86811 20.7444 5.98852 23.2425 7.71335 25.1949C8.16638 25.7077 8.6609 26.1828 9.19195 26.6149C10.5296 27.7033 12.0976 28.5185 13.8106 28.9752C13.1099 28.2901 12.4115 27.2866 11.8021 25.8249Z" fill="#77509D" />
        <path d="M22.1999 8.17577C23.0954 10.3237 23.629 13.0732 23.7279 16.0046H29.3535C29.1481 13.4343 28.1549 11.083 26.6163 9.19212C26.1842 8.66107 25.7091 8.16647 25.1963 7.71345C23.7781 6.46063 22.0722 5.52689 20.1914 5.02539C20.8921 5.71057 21.5905 6.7141 22.1999 8.17577Z" fill="#77509D" />
        <path d="M8.721 16.0045C8.98463 15.0721 9.60819 14.29 10.432 13.8178C10.6668 11.7183 11.1335 9.77928 11.8021 8.1757C12.4115 6.71404 13.1099 5.7105 13.8106 5.02539C8.82811 6.35378 5.07105 10.7162 4.64844 16.0045H8.721Z" fill="#77509D" />
        <path d="M23.7279 17.9961C23.6291 20.9275 23.0955 23.6769 22.1999 25.8249C21.5905 27.2866 20.8921 28.2901 20.1914 28.9752C25.1739 27.6468 28.9309 23.2844 29.3535 17.9961H23.7279Z" fill="#77509D" />
        <path d="M17.0013 5.60352C14.9106 5.60352 13.1353 8.84241 12.5 13.3423C14.0661 13.4484 15.3648 14.5416 15.7782 16.0036H21.7345C21.524 10.1763 19.4855 5.60352 17.0013 5.60352Z" fill="#77509D" />
        <path d="M17.0013 28.3962C19.4855 28.3962 21.524 23.8234 21.7345 17.9961H15.7782C15.3648 19.4581 14.0661 20.5513 12.5 20.6574C13.1353 25.1573 14.9106 28.3962 17.0013 28.3962Z" fill="#77509D" />
        <path d="M12.2489 15.3252C11.3255 15.3252 10.5742 16.0764 10.5742 16.9998C10.5742 17.9232 11.3254 18.6745 12.2489 18.6745C13.1723 18.6745 13.9236 17.9232 13.9236 16.9998C13.9236 16.0764 13.1722 15.3252 12.2489 15.3252Z" fill="#77509D" />
      </g>
      <defs>
        <clipPath id="clip0_2144_52926">
          <rect width="34" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
