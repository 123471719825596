import SVGButtonArrow from '../../assets/svg/SVGButtonArrow';

export default function SliderArrowPrev({ onClick }) {
  return (
    <button
      className="default-slick-arrow default-slick-arrow--prev"
      onClick={onClick}
    >
      <SVGButtonArrow />
    </button>
  );
}
