export default function SVGLogo() {
  return (
    <svg width="91" height="41" viewBox="0 0 91 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48.6449 36.9766V29.4986C48.6605 28.9178 48.5592 28.3398 48.347 27.7987C48.1349 27.2575 47.8162 26.7643 47.4098 26.348C47.0034 25.9317 46.5175 25.6008 45.9807 25.3749C45.444 25.149 44.8673 25.0326 44.2847 25.0326C43.7022 25.0326 43.1255 25.149 42.5887 25.3749C42.052 25.6008 41.5661 25.9317 41.1597 26.348C40.7533 26.7643 40.4346 27.2575 40.2225 27.7987C40.0103 28.3398 39.9091 28.9178 39.9246 29.4986V36.9766H36.7852V21.9805L39.0125 24.1802C40.0646 23.1432 41.4007 22.4397 42.8528 22.1583C44.3048 21.8769 45.8079 22.03 47.1728 22.5986C48.5377 23.1671 49.7036 24.1256 50.5236 25.3533C51.3436 26.5811 51.7812 28.0234 51.7812 29.4986C51.7812 32.6389 51.7812 35.3987 51.7812 36.9766H48.6449Z" fill="#F0DFFF" />
      <path d="M31.2539 36.9787V25.081L34.3269 21.9492V36.9787H31.2539Z" fill="#F0DFFF" />
      <path d="M54.2383 36.9821V25.0844L57.3113 21.9526V36.9821H54.2383ZM55.7611 19.1867C55.4599 19.1867 55.1655 19.0958 54.9151 18.9254C54.6646 18.7551 54.4695 18.5129 54.3542 18.2297C54.2389 17.9464 54.2088 17.6346 54.2675 17.3339C54.3263 17.0331 54.4713 16.7569 54.6843 16.54C54.8973 16.3232 55.1686 16.1755 55.464 16.1157C55.7594 16.0559 56.0656 16.0866 56.3438 16.204C56.6221 16.3213 56.8599 16.52 57.0272 16.775C57.1945 17.0299 57.2839 17.3297 57.2839 17.6363C57.2807 18.0432 57.1205 18.4326 56.8379 18.7203C56.5553 19.008 56.1729 19.1711 55.7732 19.1743L55.7611 19.1867Z" fill="#F0DFFF" />
      <path d="M54.9151 18.9254C55.1655 19.0958 55.4599 19.1867 55.7611 19.1867L55.7732 19.1743C56.1729 19.1711 56.5553 19.008 56.8379 18.7203C57.1205 18.4326 57.2807 18.0432 57.2839 17.6363C57.2839 17.3297 57.1945 17.0299 57.0272 16.775C56.8599 16.52 56.6221 16.3213 56.3438 16.204C56.0656 16.0866 55.7594 16.0559 55.464 16.1157C55.1686 16.1755 54.8973 16.3232 54.6843 16.54C54.4713 16.7569 54.3263 17.0331 54.2675 17.3339C54.2088 17.6346 54.2389 17.9464 54.3542 18.2297C54.4695 18.5129 54.6646 18.7551 54.9151 18.9254Z" fill="#F0DFFF" />
      <path d="M54.9072 18.8942C55.1577 19.0645 55.4521 19.1555 55.7533 19.1555L55.7654 19.1431C56.1651 19.1398 56.5475 18.9768 56.8301 18.689C57.1127 18.4013 57.2729 18.012 57.276 17.6051C57.276 17.2984 57.1867 16.9987 57.0194 16.7437C56.8521 16.4888 56.6143 16.2901 56.336 16.1727C56.0578 16.0554 55.7516 16.0247 55.4562 16.0845C55.1608 16.1443 54.8895 16.292 54.6765 16.5088C54.4635 16.7256 54.3185 17.0019 54.2597 17.3026C54.201 17.6034 54.2311 17.9151 54.3464 18.1984C54.4616 18.4817 54.6568 18.7238 54.9072 18.8942Z" fill="#B66DFD" />
      <path d="M17.1172 36.9766V21.9805L19.334 24.1807C20.5661 22.9612 22.183 22.2078 23.9085 22.0492C25.6341 21.8906 27.3611 22.3366 28.7945 23.3111L26.5128 25.5949C25.8499 25.2694 25.1152 25.1181 24.3777 25.1552C23.6403 25.1922 22.9244 25.4164 22.2974 25.8067C21.6704 26.1969 21.1528 26.7404 20.7934 27.386C20.434 28.0315 20.2446 28.758 20.2429 29.4971V36.9766H17.1172Z" fill="#F0DFFF" />
      <path d="M67.2784 36.9803C65.2885 36.9786 63.3805 36.1883 61.9734 34.7827C60.5663 33.3772 59.7751 31.4713 59.7734 29.4835V18.0508L66.8902 25.1846H62.9064V29.3595C62.9064 29.4029 62.9064 29.4432 62.9064 29.4835C62.9072 30.5164 63.275 31.5154 63.9442 32.3027C64.6134 33.0901 65.5406 33.6146 66.5608 33.7829C67.5809 33.9512 68.6278 33.7523 69.5149 33.2217C70.402 32.6911 71.0718 31.8632 71.405 30.8855H74.6466C74.3173 32.5977 73.402 34.1421 72.0577 35.2541C70.7134 36.366 69.0237 36.9763 67.2784 36.9803Z" fill="#F0DFFF" />
      <path d="M83.4559 36.9079C81.4715 36.8981 79.5716 36.1079 78.1704 34.7097C76.7693 33.3115 75.9806 31.4187 75.9766 29.4442V21.9805H79.1142C79.1142 21.9805 79.1142 28.2677 79.1142 29.4442C79.1445 30.5752 79.6174 31.6498 80.4321 32.4391C81.2468 33.2284 82.339 33.6701 83.4762 33.6701C84.6133 33.6701 85.7055 33.2284 86.5202 32.4391C87.3349 31.6498 87.8078 30.5752 87.8381 29.4442C87.8381 28.2677 87.8381 21.9805 87.8381 21.9805H90.9727V29.4442C90.958 31.8732 90.24 34.2466 88.9047 36.2801C87.5694 38.3136 85.6734 39.921 83.4436 40.91L83.4559 36.9079Z" fill="#F0DFFF" />
      <path d="M44.2041 19.7481C42.6974 19.7481 41.2245 19.3013 39.9717 18.4642C38.7189 17.6271 37.7425 16.4373 37.1659 15.0452C36.5893 13.6532 36.4384 12.1214 36.7323 10.6437C37.0263 9.16587 37.7518 7.80846 38.8173 6.74304C39.8827 5.67762 41.2401 4.95205 42.7179 4.6581C44.1957 4.36415 45.7274 4.51504 47.1195 5.09164C48.5115 5.66824 49.7013 6.64466 50.5384 7.89746C51.3755 9.15026 51.8223 10.6231 51.8223 12.1299C51.8198 14.1496 51.0163 16.0858 49.5882 17.514C48.1601 18.9421 46.2238 19.7456 44.2041 19.7481ZM44.2041 7.70147C43.3268 7.70148 42.4693 7.96187 41.7401 8.44966C41.0109 8.93745 40.4429 9.63067 40.1081 10.4415C39.7732 11.2524 39.6866 12.1444 39.8591 13.0046C40.0317 13.8647 40.4556 14.6543 41.0773 15.2733C41.6989 15.8923 42.4903 16.3129 43.3512 16.4818C44.2121 16.6506 45.1038 16.5602 45.9132 16.2219C46.7226 15.8835 47.4134 15.3126 47.8981 14.5813C48.3827 13.8501 48.6395 12.9914 48.6357 12.1141C48.6349 10.9394 48.1676 9.81295 47.3366 8.98255C46.5056 8.15215 45.3789 7.68569 44.2041 7.68569V7.70147Z" fill="#F0DFFF" />
      <path d="M7.4429 36.9764C5.46944 36.9747 3.57725 36.1844 2.1818 34.7788C0.786349 33.3733 0.00163096 31.4674 0 29.4796V18.0469L7.05798 25.1807H3.10712V29.3556C3.10712 29.399 3.10712 29.4393 3.10712 29.4796C3.1079 30.5125 3.47257 31.5115 4.13626 32.2988C4.79994 33.0862 5.71953 33.6107 6.73125 33.779C7.74297 33.9473 8.78117 33.7484 9.66094 33.2178C10.5407 32.6872 11.205 31.8593 11.5354 30.8816H14.7503C14.4237 32.5938 13.5159 34.1382 12.1827 35.2502C10.8495 36.3621 9.17386 36.9724 7.4429 36.9764Z" fill="#B66DFD" />
      <path d="M8.65814 19.4687C6.56285 19.4687 4.55335 18.6569 3.07176 17.2119C1.59016 15.7668 0.757812 13.807 0.757812 11.7634C0.757812 9.71987 1.59016 7.76004 3.07176 6.31503C4.55335 4.87002 6.56285 4.05823 8.65814 4.05823H12.4073L16.5585 0V11.773C16.5533 13.8141 15.7189 15.77 14.2382 17.2124C12.7574 18.6548 10.7509 19.4661 8.65814 19.4687ZM8.65814 7.28441C7.74836 7.28442 6.85906 7.54779 6.10288 8.04115C5.34669 8.53452 4.75767 9.23565 4.41042 10.0558C4.06316 10.8759 3.9733 11.7781 4.15222 12.6481C4.33114 13.5181 4.77078 14.3167 5.41547 14.9428C6.06016 15.5689 6.88086 15.9943 7.77363 16.165C8.6664 16.3358 9.59107 16.2443 10.4305 15.9022C11.2699 15.56 11.9863 14.9825 12.4889 14.2429C12.9915 13.5033 13.2577 12.6348 13.2538 11.7475C13.253 10.5593 12.7684 9.42002 11.9066 8.58013C11.0449 7.74023 9.87643 7.26845 8.65814 7.26845V7.28441Z" fill="#B66DFD" />
      <path d="M26.7158 19.748C25.2091 19.748 23.7362 19.3012 22.4834 18.4641C21.2306 17.627 20.2542 16.4373 19.6776 15.0452C19.101 13.6532 18.9501 12.1214 19.244 10.6436C19.538 9.16586 20.2636 7.80846 21.329 6.74304C22.3944 5.67762 23.7518 4.95204 25.2296 4.6581C26.7074 4.36415 28.2391 4.51504 29.6312 5.09164C31.0232 5.66824 32.213 6.64466 33.0501 7.89745C33.8872 9.15025 34.334 10.6231 34.334 12.1299V19.748L32.1024 17.5165L31.9701 17.6488C30.5561 19.0019 28.6729 19.7543 26.7158 19.748ZM26.7158 7.70146C25.8386 7.70147 24.981 7.96186 24.2518 8.44965C23.5227 8.93744 22.9547 9.63066 22.6198 10.4415C22.2849 11.2524 22.1983 12.1444 22.3708 13.0046C22.5434 13.8647 22.9673 14.6543 23.589 15.2733C24.2106 15.8923 25.002 16.3129 25.8629 16.4817C26.7238 16.6506 27.6155 16.5602 28.4249 16.2218C29.2343 15.8835 29.9251 15.3126 30.4098 14.5813C30.8944 13.8501 31.1512 12.9914 31.1474 12.1141C31.1424 10.9421 30.6733 9.81973 29.8428 8.99273C29.0122 8.16574 27.8879 7.70145 26.7158 7.70146Z" fill="#F0DFFF" />
    </svg>
  );
}
