export default function SVGStroke({
  radius = 2,
}) {
  return (
    <svg version="1.1" xmlns="//www.w3.org/2000/svg" style={{ display: 'none' }}>
      <defs>
        <filter id="stroke-text-svg-filter">
          <feMorphology operator="dilate" radius={radius} />
          <feComposite operator="xor" in="SourceGraphic" />
        </filter>
      </defs>
    </svg>
  );
}
