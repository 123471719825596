import SVGLogo from '../../assets/svg/SVGLogo';
import { SOCIALS_LIST } from '../../features/constants';
import { getId } from '../../features/helpers';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';

export default function FooterBlock() {
  const { t } = useCustomTranslation();
  return (
    <div className="footer">
      <div className="container">
        <div className="footer__top">
          <SVGLogo />
        </div>
        <div className="footer__socials">
          <ul>
            {SOCIALS_LIST.map(({ title, link, icon }) => {
              const id = getId();
              return (
                <li key={id} title={title}>
                  <a href={t(link)}>
                    {icon}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
