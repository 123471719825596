export default function SVGNeural() {
  return (
    <svg width="58" height="68" viewBox="0 0 58 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.4439 41.5586C33.8807 41.5586 41.833 40.9757 41.833 33.4782C41.833 27.7272 37.1983 20.7803 31.4439 20.7803C25.6894 20.7803 21.0547 27.7272 21.0547 33.4782C21.0547 40.9757 29.007 41.5586 31.4439 41.5586ZM32.5982 32.3238H30.2895C29.3711 32.3238 28.4902 31.9589 27.8408 31.3095C27.1913 30.66 26.8265 29.7792 26.8265 28.8607C26.8265 27.9423 27.1913 27.0614 27.8408 26.412C28.4902 25.7625 29.3711 25.3977 30.2895 25.3977V24.2433H32.5982V25.3977C33.5167 25.3977 34.3975 25.7625 35.047 26.412C35.6964 27.0614 36.0613 27.9423 36.0613 28.8607H33.7526C33.7526 28.5546 33.631 28.261 33.4145 28.0445C33.198 27.828 32.9044 27.7064 32.5982 27.7064H30.2895C29.9834 27.7064 29.6897 27.828 29.4733 28.0445C29.2568 28.261 29.1352 28.5546 29.1352 28.8607C29.1352 29.1669 29.2568 29.4605 29.4733 29.677C29.6897 29.8935 29.9834 30.0151 30.2895 30.0151H32.5982C33.5167 30.0151 34.3975 30.38 35.047 31.0294C35.6964 31.6789 36.0613 32.5597 36.0613 33.4782C36.0613 34.3966 35.6964 35.2775 35.047 35.9269C34.3975 36.5764 33.5167 36.9412 32.5982 36.9412V38.0956H30.2895V36.9412C29.3711 36.9412 28.4902 36.5764 27.8408 35.9269C27.1913 35.2775 26.8265 34.3966 26.8265 33.4782H29.1352C29.1352 33.7843 29.2568 34.0779 29.4733 34.2944C29.6897 34.5109 29.9834 34.6325 30.2895 34.6325H32.5982C32.9044 34.6325 33.198 34.5109 33.4145 34.2944C33.631 34.0779 33.7526 33.7843 33.7526 33.4782C33.7526 33.172 33.631 32.8784 33.4145 32.6619C33.198 32.4454 32.9044 32.3238 32.5982 32.3238Z" fill="#F0DFFF" />
      <path d="M29.9321 17.2553L28.5469 16.7936L29.1125 18.7779C30.6479 18.3685 32.2638 18.3685 33.7992 18.7779L34.4456 16.5107L30.5277 17.2911C30.3295 17.3322 30.124 17.3199 29.9321 17.2553Z" fill="#F0DFFF" />
      <path d="M30.2967 3.94302V10.3901H32.6054V3.94302L34.098 5.4356L35.7303 3.80104L32.2672 0.337979C32.0507 0.121571 31.7572 0 31.4511 0C31.145 0 30.8514 0.121571 30.6349 0.337979L27.1719 3.80104L28.8041 5.4356L30.2967 3.94302Z" fill="#F0DFFF" />
      <path d="M11.8261 12.0208V20.7777H14.1348V12.0208L16.7817 14.6677L18.414 13.0355L13.7965 8.41806C13.5801 8.20165 13.2865 8.08008 12.9804 8.08008C12.6743 8.08008 12.3808 8.20165 12.1643 8.41806L7.54688 13.0355L9.17913 14.6677L11.8261 12.0208Z" fill="#F0DFFF" />
      <path d="M49.1096 7.26278L44.4922 11.8802L46.1244 13.5124L48.7714 10.8655V20.7768H51.0801V10.8655L53.727 13.5124L55.3593 11.8802L50.7419 7.26278C50.5254 7.04638 50.2318 6.9248 49.9257 6.9248C49.6196 6.9248 49.3261 7.04638 49.1096 7.26278Z" fill="#F0DFFF" />
      <path d="M49.9219 35.7852H52.2306V38.0939H49.9219V35.7852Z" fill="#F0DFFF" />
      <path d="M47.6094 33.4766H49.9181V35.7853H47.6094V33.4766Z" fill="#F0DFFF" />
      <path d="M49.9219 31.167H52.2306V33.4757H49.9219V31.167Z" fill="#F0DFFF" />
      <path d="M52.2266 33.4766H54.5353V35.7853H52.2266V33.4766Z" fill="#F0DFFF" />
      <path d="M55.6875 24.2422H57.9962V26.5509H55.6875V24.2422Z" fill="#F0DFFF" />
      <path d="M10.6719 35.7852H12.9806V38.0939H10.6719V35.7852Z" fill="#F0DFFF" />
      <path d="M12.9766 33.4766H15.2853V35.7853H12.9766V33.4766Z" fill="#F0DFFF" />
      <path d="M10.6719 31.167H12.9806V33.4757H10.6719V31.167Z" fill="#F0DFFF" />
      <path d="M8.35938 33.4766H10.6681V35.7853H8.35938V33.4766Z" fill="#F0DFFF" />
      <path d="M2.59375 26.5498H4.90246V28.8585H2.59375V26.5498Z" fill="#F0DFFF" />
      <path d="M4.89844 24.2422H7.20714V26.5509H4.89844V24.2422Z" fill="#F0DFFF" />
      <path d="M2.59375 21.9316H4.90246V24.2403H2.59375V21.9316Z" fill="#F0DFFF" />
      <path d="M0.28125 24.2422H2.58996V26.5509H0.28125V24.2422Z" fill="#F0DFFF" />
      <path d="M46.1252 45.8291L39.199 52.7552C38.0153 53.7836 36.4831 54.3204 34.9164 54.2559H30.299V51.9472H32.6077V49.6385H21.0641C20.5417 49.7201 20.0576 49.9621 19.6789 50.3311L17.3125 53.4894L24.533 60.7099C25.4235 59.6974 26.6528 59.0444 27.9903 58.8733H37.2251C38.1786 58.943 39.1234 58.6517 39.872 58.0572L49.1068 48.8224C49.3321 48.6205 49.5142 48.375 49.6419 48.1008C49.7697 47.8266 49.8406 47.5294 49.8503 47.227C49.8329 46.9664 49.7618 46.7121 49.6414 46.4803C49.521 46.2485 49.354 46.044 49.1507 45.8799C48.9585 45.6726 48.7264 45.5064 48.4683 45.3911C48.2101 45.2759 47.9314 45.214 47.6488 45.2093C47.3661 45.2045 47.0855 45.257 46.8236 45.3635C46.5618 45.47 46.3242 45.6284 46.1252 45.8291Z" fill="#F0DFFF" />
      <path d="M19.356 58.8005L14.9695 54.4139L14.3704 53.8148C14.1543 53.6012 13.8627 53.4814 13.5589 53.4814C13.255 53.4814 12.9634 53.6012 12.7474 53.8148L11.3841 55.1781C11.2772 55.2845 11.1924 55.411 11.1345 55.5502C11.0767 55.6895 11.0469 55.8388 11.0469 55.9896C11.0469 56.1404 11.0767 56.2898 11.1345 56.429C11.1924 56.5683 11.2772 56.6947 11.3841 56.8011L21.3992 66.8163C21.6157 67.0327 21.9093 67.1543 22.2154 67.1543C22.5215 67.1543 22.815 67.0327 23.0315 66.8163L24.3844 65.4646C24.6008 65.2481 24.7224 64.9545 24.7224 64.6484C24.7224 64.3423 24.6008 64.0488 24.3844 63.8323L19.356 58.8005Z" fill="#F0DFFF" />
    </svg>
  );
}
