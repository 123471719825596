import { useState } from 'react';
import SVGButtonArrow from '../../assets/svg/SVGButtonArrow';
import SVGPlayPromo from '../../assets/svg/SVGPlayPromo';
import SVGPlus from '../../assets/svg/SVGPlus';
import SVGScrollArrow from '../../assets/svg/SVGScrollArrow';
import SVGDiscrod from '../../assets/svg/socials/SVGDiscrod';
import SVGFacebook from '../../assets/svg/socials/SVGFacebook';
import SVGInstagram from '../../assets/svg/socials/SVGInstagram';
import SVGTelegram from '../../assets/svg/socials/SVGTelegram';
import SVGTwitter from '../../assets/svg/socials/SVGTwitter';
import SVGYoutube from '../../assets/svg/socials/SVGYoutube';
import SafeHTML from '../../components/SafeHTML';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import Button from '../../components/Button';
import PromoModal from '../../components/Modals/PromoModal';
import { DASHBOARD_LINK, SOCIALS_LIST } from '../../features/constants';
import { getId } from '../../features/helpers';

export default function HeaderBlock() {
  const [video, toggleVideo] = useState(true);
  const [promo, togglePromo] = useState(false);
  const { t } = useCustomTranslation();
  const prefix = t('classPrefix');
  return (
    <div className="top">
      <div className="header-block">
        <div className="header-block__plus header-block__plus--first">
          <SVGPlus fill="rgba(255,255,255,.1)" />
          <SVGPlus stroke="#A863ED" />
        </div>
        <div className="header-block__plus header-block__plus--second">
          <SVGPlus fill="rgba(255,255,255,.1)" />
          <SVGPlus stroke="#A863ED" />
        </div>
        <div className="header-block__plus header-block__plus--thrid">
          <SVGPlus fill="rgba(255,255,255,.1)" />
          <SVGPlus stroke="#A863ED" />
        </div>
        <div className="header-block__left">
          <div className="header-block__scroll-down">
            <SVGScrollArrow fill="#F0DFFF" opacity={0.2} />
            <span>{ t('common.scrollDown') }</span>
          </div>
        </div>
        <div className="header-block__center">
          <div className="header-block__line" />
          <div className="header-block__video">
            <div className="header-block__video--button" onClick={() => togglePromo(true)}>
              <SVGPlayPromo />
            </div>
            <SafeHTML html={t('headerBlock.videoDao')}>
              <div className="header-block__video--title" />
            </SafeHTML>
          </div>
          <div className="header-block__content">
            <div className="header-block__title-top">
              { t('headerBlock.title.firstLine') }
            </div>
            <SafeHTML html={t('headerBlock.title.secondLine')}>
              <div className={`header-block__title-main ${prefix}title`} />
            </SafeHTML>
            <div className="header-block__bottom">
              <SafeHTML html={t('headerBlock.title.thridLine')}>
                <div className="header-block__bottom--title" />
              </SafeHTML>

              <div className="header-block__button-holder">
                <a href={`${DASHBOARD_LINK}/reg`} className="default-button header-block__button" onClick={() => toggleVideo(!video)}>
                  <span>
                    { t('headerBlock.startButton') }
                  </span>
                  <i className="icon">
                    <SVGButtonArrow />
                  </i>
                </a>
                <SafeHTML html={t('common.webReg')}>
                  <div className="button-subtitle" />
                </SafeHTML>
              </div>
            </div>
          </div>
        </div>
        <div className="header-block__right">
          <div className="header-block__socials">
            <div className="header-block__socials--title">
              { t('common.ourSocials') }
            </div>
            <ul>
              {SOCIALS_LIST.map(({ title, link, icon }) => {
                const id = getId();
                return (
                  <li key={id} title={title}>
                    <a href={t(link)}>
                      {icon}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className={`header-block__background ${!video ? 'this-3d' : ''}`}>
          {
            video
              ? <video src={require('../../assets/video/background.mov')} loop muted playsInline autoPlay preload="auto" />
              : <iframe src="https://my.spline.design/untitled-7c8105e63688f30eadbc18eba56e343d/" title="3d" />
          }
        </div>
      </div>
      <PromoModal open={promo} onClose={() => togglePromo(false)} />
    </div>
  );
}
