export default function SVGProgramsVideo() {
  return (
    <svg width="119" height="84" viewBox="0 0 119 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7">
        <path d="M113.424 7.18684C109.164 2.08257 101.298 0.000488281 86.2765 0.000488281H31.7471C16.3815 0.000488281 8.38242 2.21687 4.1381 7.65115C0 12.9496 0 20.7564 0 31.5613V52.1553C0 73.0877 4.90983 83.7157 31.7471 83.7157H86.2768C99.3037 83.7157 106.522 81.8784 111.192 77.3739C115.981 72.7547 118.025 65.2126 118.025 52.1553V31.5613C118.025 20.1666 117.705 12.3138 113.424 7.18684ZM75.7722 44.7015L51.0107 57.7446C50.4571 58.0362 49.8517 58.1809 49.2471 58.1809C48.5626 58.1809 47.8796 57.9952 47.2746 57.626C46.1355 56.9303 45.4399 55.6859 45.4399 54.3437V28.3411C45.4399 27.0011 46.1336 25.7579 47.2704 25.0618C48.4076 24.3657 49.822 24.3181 51.0019 24.9359L75.7635 37.895C77.0233 38.5542 77.8145 39.865 77.8164 41.2952C77.8179 42.7265 77.0302 44.0392 75.7722 44.7015Z" fill="url(#paint0_linear_94_1259)" />
      </g>
      <path opacity="0.9" d="M94.3137 18.5997C91.5489 15.1919 86.4443 13.8018 76.6957 13.8018H41.3082C31.3365 13.8018 26.1454 15.2815 23.391 18.9097C20.7056 22.4472 20.7056 27.6595 20.7056 34.8734V48.623C20.7056 62.5986 23.8919 69.6944 41.3082 69.6944H76.696C85.1499 69.6944 89.8345 68.4677 92.8651 65.4603C95.9731 62.3762 97.2991 57.3407 97.2991 48.623V34.8734C97.2991 27.2657 97.0913 22.0227 94.3137 18.5997ZM69.8789 43.6464L53.8096 52.3547C53.4503 52.5494 53.0575 52.646 52.6651 52.646C52.2209 52.646 51.7776 52.522 51.385 52.2755C50.6457 51.8111 50.1943 50.9802 50.1943 50.0841V32.7234C50.1943 31.8288 50.6445 30.9987 51.3823 30.534C52.1203 30.0692 53.0382 30.0375 53.8039 30.4499L69.8732 39.1021C70.6908 39.5422 71.2042 40.4174 71.2054 41.3722C71.2064 42.3278 70.6952 43.2043 69.8789 43.6464Z" fill="url(#paint1_linear_94_1259)" />
      <path d="M87.2526 24.0444C85.0464 21.4218 80.9731 20.3521 73.1941 20.3521H44.9561C36.999 20.3521 32.8567 21.4908 30.6588 24.283C28.5159 27.0053 28.5159 31.0165 28.5159 36.5681V47.1494C28.5159 57.9046 31.0584 63.3653 44.9561 63.3653H73.1943C79.9402 63.3653 83.6783 62.4213 86.0967 60.1068C88.5767 57.7334 89.6349 53.8583 89.6349 47.1494V36.5681C89.6349 30.7135 89.4691 26.6787 87.2526 24.0444ZM67.7545 43.3196L54.9317 50.0212C54.645 50.171 54.3316 50.2454 54.0185 50.2454C53.664 50.2454 53.3103 50.1499 52.997 49.9603C52.4071 49.6028 52.0469 48.9634 52.0469 48.2738V34.9136C52.0469 34.2251 52.4061 33.5863 52.9948 33.2286C53.5837 32.871 54.3162 32.8466 54.9272 33.164L67.7499 39.8224C68.4023 40.1611 68.812 40.8346 68.813 41.5694C68.8138 42.3048 68.4059 42.9793 67.7545 43.3196Z" fill="#DDBBFF" />
      <defs>
        <linearGradient id="paint0_linear_94_1259" x1="58.7501" y1="94.8777" x2="58.7501" y2="9.30218" gradientUnits="userSpaceOnUse">
          <stop stopColor="rgba(3, 0, 91, 0.6)" />
          <stop offset="1" stopColor="rgba(125, 0, 227, 0.9)" />
        </linearGradient>
        <linearGradient id="paint1_linear_94_1259" x1="58.8321" y1="77.1467" x2="58.8321" y2="20.012" gradientUnits="userSpaceOnUse">
          <stop stopColor="#77509D" />
          <stop offset="1" stopColor="#B66DFD" />
        </linearGradient>
      </defs>
    </svg>
  );
}
