import Slider from 'react-slick';
import SVGLine from '../../assets/svg/SVGLine';
import SVGPlus from '../../assets/svg/SVGPlus';
import SVGBlockchain from '../../assets/svg/tech/SVGBlockchain';
import SVGNeural from '../../assets/svg/tech/SVGNeural';
import SVGSmartContract from '../../assets/svg/tech/SVGSmartContract';
import SliderArrowNext from '../../components/SliderArrowNext';
import SliderArrowPrev from '../../components/SliderArrowPrev';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import SafeHTML from '../../components/SafeHTML';

export default function BlockchainBlock() {
  const { t } = useCustomTranslation();
  const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
    responsive: [
      {
        breakpoint: 999999,
        settings: 'unslick',
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="blockchain" id="technologies">
      <div className="blockchain__line blockchain__line--first" />
      <div className="blockchain__line blockchain__line--second" />

      <div className="blockchain__plus blockchain__plus--top">
        <SVGPlus fill="rgba(0, 0, 0, 0.05)" />
        <SVGPlus stroke="#A863ED" />
      </div>
      <div className="blockchain__plus blockchain__plus--first">
        <SVGPlus fill="rgba(0, 0, 0, 0.05)" />
        <SVGPlus stroke="#A863ED" />
      </div>
      <div className="blockchain__plus blockchain__plus--second">
        <SVGPlus fill="rgba(0, 0, 0, 0.05)" />
        <SVGPlus stroke="#A863ED" />
      </div>
      <div className="blockchain__plus blockchain__plus--thrid">
        <SVGPlus fill="rgba(0, 0, 0, 0.05)" />
        <SVGPlus stroke="#A863ED" />
      </div>
      <div className="blockchain__plus blockchain__plus--bottom">
        <SVGPlus fill="rgba(0, 0, 0, 0.05)" />
        <SVGPlus stroke="#A863ED" />
      </div>

      <div className="container">
        <div className="blockchain__title">
          <SafeHTML html={t('blockChainBlock.title')} />
          <SVGLine />
        </div>
        <SafeHTML html={t('blockChainBlock.text')}>
          <div className="blockchain__desc" />
        </SafeHTML>

        <div className="blockchain__we-use">
          {t('blockChainBlock.weUse')}
        </div>

        <Slider {...sliderSettings} className="blockchain__row">
          <div className="blockchain__cell">
            <div className="blockchain__box">
              <div className="blockchain__box--inset">
                <div className="blockchain__box--icon">
                  <SVGBlockchain />
                </div>
                <SafeHTML html={t('blockChainBlock.list.element1.title')}>
                  <div className="blockchain__box--title" />
                </SafeHTML>
                <SafeHTML html={t('blockChainBlock.list.element1.text')}>
                  <div className="blockchain__box--text" />
                </SafeHTML>
              </div>
            </div>
          </div>
          <div className="blockchain__cell">
            <div className="blockchain__box">
              <div className="blockchain__box--inset">
                <div className="blockchain__box--icon">
                  <SVGSmartContract />
                </div>
                <SafeHTML html={t('blockChainBlock.list.element2.title')}>
                  <div className="blockchain__box--title" />
                </SafeHTML>
                <SafeHTML html={t('blockChainBlock.list.element2.text')}>
                  <div className="blockchain__box--text" />
                </SafeHTML>
              </div>
            </div>
          </div>
          <div className="blockchain__cell">
            <div className="blockchain__box">
              <div className="blockchain__box--inset">
                <div className="blockchain__box--icon">
                  <SVGNeural />
                </div>
                <SafeHTML html={t('blockChainBlock.list.element3.title')}>
                  <div className="blockchain__box--title" />
                </SafeHTML>
                <SafeHTML html={t('blockChainBlock.list.element3.text')}>
                  <div className="blockchain__box--text" />
                </SafeHTML>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
