import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';

export default function Modal({
  portal = false,
  customClass = null,
  show = false,
  onClose = null,
  children,
}) {
  const content = (
    <AnimatePresence>
      {show && (
        <motion.div
          className={`modal ${customClass || null}`}
          onClick={() => onClose && onClose()}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <motion.div
            className="modal__content"
            initial={{
              opacity: 0,
              y: -120,
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={{
              opacity: 0,
              y: -120,
            }}
          >
            { children }
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return portal ? createPortal(content, document.body) : content;
}
