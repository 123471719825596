import SVGIdeaDao from '../../assets/svg/SVGIdeaDao';
import SVGLineBig from '../../assets/svg/SVGLineBig';
import SafeHTML from '../../components/SafeHTML';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';

export default function ConsumerPatternBlock() {
  const { t } = useCustomTranslation();
  return (
    <div className="consumer-pattern">
      <div className="consumer-pattern__logos">
        <SVGIdeaDao />
        <SVGIdeaDao />
      </div>
      <div className="container">
        <div className="consumer-pattern__holder">
          <div className="consumer-pattern__box">
            <div className="consumer-pattern__top-title">
              { t('consumerPatternBlock.topTitle') }
            </div>
            <div className="consumer-pattern__title">
              <span>{ t('consumerPatternBlock.title') }</span>
              <SVGLineBig />
            </div>
            <div className="consumer-pattern__text">
              <SafeHTML html={t('consumerPatternBlock.text')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
