export default function SVGHandshake() {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2144_52965)">
        <path d="M14.8203 24.4885C14.4301 24.0985 13.9746 23.8134 13.4888 23.6311C14.5558 22.2003 14.385 20.0296 13.0928 18.7857L13.0595 18.7524C11.9568 17.6498 10.3335 17.3777 8.97347 17.9356C8.79796 17.344 8.47721 16.8018 8.02864 16.3533L7.99484 16.3195C6.59712 14.8628 4.08105 14.863 2.68327 16.32L2.58904 16.4142L2.39341 16.2186C-0.797813 13.0274 -0.797813 7.83479 2.39347 4.64344C5.58476 1.45215 10.7773 1.45222 13.9687 4.64344L15.8773 6.55215L12.2559 10.0705C10.6416 11.5677 10.6193 14.324 12.21 15.8478C13.7189 17.4203 16.4335 17.4203 17.9422 15.8478L19.1907 14.5994C19.2298 14.5603 19.294 14.5603 19.3332 14.5994L26.8142 22.0803C26.8158 22.0819 26.8174 22.0835 26.819 22.0851L27.0845 22.3506C27.5788 22.8249 27.5788 23.6783 27.0845 24.1527C26.5878 24.6493 25.7794 24.6494 25.2826 24.1526C25.2684 24.1384 25.2532 24.126 25.2384 24.1127C25.2115 24.0841 25.1852 24.0552 25.1572 24.0272L23.0423 21.9123C22.6014 21.4714 21.8863 21.4714 21.4454 21.9123C21.0044 22.3533 21.0044 23.0683 21.4454 23.5093L23.5603 25.6242C24.1233 26.1644 24.1233 27.1366 23.5603 27.6769C23.0199 28.24 22.0479 28.24 21.5075 27.6769L19.4038 25.5732C18.9628 25.1322 18.2478 25.1322 17.8068 25.5732C17.3658 26.0141 17.3658 26.7292 17.8068 27.1701L19.8993 29.2626C20.4738 29.8278 20.4811 30.7568 19.91 31.3271C19.3696 31.8903 18.3976 31.8903 17.8573 31.3271L15.5 28.9699C16.2906 27.5584 16.0226 25.6536 14.8535 24.5219L14.8203 24.4885Z" fill="#77509D" />
        <path d="M31.741 4.90251C34.7494 7.91098 34.7528 12.8039 31.7517 15.817L27.6365 19.7086L20.9301 13.0022C20.052 12.087 18.4718 12.0871 17.5937 13.0022L16.3452 14.2506C15.6771 14.947 14.475 14.9469 13.8069 14.2506C13.464 13.9077 13.2773 13.4516 13.2813 12.9667C13.2853 12.4817 13.4795 12.0289 13.828 11.6916L20.8041 4.914C23.8137 1.89138 28.7252 1.88594 31.741 4.90251Z" fill="#77509D" />
        <path d="M5.98863 25.8905L5.9553 25.8572C5.37431 25.2998 5.37424 24.2966 5.9553 23.7391L8.02883 21.6655C8.02923 21.6651 8.02963 21.6647 8.03003 21.6643L9.34461 20.3496C9.93987 19.7524 10.9188 19.7696 11.496 20.383C12.077 20.9405 12.0771 21.9437 11.496 22.5011L8.10666 25.8905C7.52282 26.4744 6.57261 26.4744 5.98863 25.8905Z" fill="#77509D" />
        <path d="M2.24078 22.1413L2.20731 22.1078C1.62354 21.5238 1.6236 20.5737 2.20751 19.9898L4.28105 17.9162C4.83853 17.3352 5.84166 17.3352 6.39914 17.9162L6.43294 17.95C7.0134 18.5069 7.0134 19.5103 6.43268 20.0675L4.35894 22.1413C3.7749 22.7253 2.82469 22.7252 2.24078 22.1413Z" fill="#77509D" />
        <path d="M11.9136 29.5808C11.3297 30.1647 10.3794 30.1647 9.79547 29.5808L9.7622 29.5474C9.17822 28.9635 9.17822 28.0133 9.7622 27.4293L11.1064 26.085C11.7016 25.4877 12.6806 25.5049 13.2578 26.1183C13.8388 26.6757 13.8389 27.6789 13.2578 28.2364L11.9136 29.5808Z" fill="#77509D" />
      </g>
      <defs>
        <clipPath id="clip0_2144_52965">
          <rect width="34" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
