import { useState } from 'react';
import SVGLogo from '../../assets/svg/SVGLogo';
import SVGWhitePaper from '../../assets/svg/SVGWhitePaper';
import SVGSmartChain from '../../assets/svg/SVGSmartChain';
import SVGKey from '../../assets/svg/SVGKey';
import LanguageSelector from '../LanguageSelector';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import Button from '../Button';
import BigMenu from '../BigMenu';
import SafeHTML from '../SafeHTML';
import { DASHBOARD_LINK } from '../../features/constants';

export default function Menu() {
  const { t } = useCustomTranslation();
  const [showMenu, toggleMenu] = useState(false);
  const menuList = [
    { link: '#', title: t('menu.homePage') },
    { link: '#', title: t('menu.programs') },
    { link: '#', title: t('menu.aboutUs') },
  ];
  return (
    <>
      <div className="menu__toggle" onClick={() => toggleMenu(!showMenu)}>
        {/* <div className={`menu__toggle--box ${showMenu ? 'this-active' : ''}`}>
          <div className="menu__toggle--box__inset">
            <span />
            <span />
            <span />
          </div>
        </div> */}
        {/* <div className="menu__toggle--title">
          { t('menu.button') }
        </div> */}
      </div>
      <div className="menu">
        <BigMenu show={showMenu} onClose={() => toggleMenu(false)} />
        <div className="container">
          <div className="menu__wrapper">
            <div className="menu__side menu__side--right">
              <div className={`menu__logo ${showMenu ? 'menu__logo--open' : ''}`}>
                <SVGLogo />
                <SafeHTML html={t('menu.globalConsumerFund')}>
                  <span />
                </SafeHTML>
              </div>
            </div>
            <div className="menu__side menu__side--left">
              {/* <a href="#" className="menu__white-paper">
                <i className="icon">
                  <SVGWhitePaper />
                </i>
                { t('menu.whitePaper') }
              </a> */}
              <div className="menu__smart-chain">
                <i className="icon">
                  <SVGSmartChain />
                </i>
                { t('menu.smartChain') }
              </div>
              <div className="menu__sign">
                <a href={`${DASHBOARD_LINK}/auth`} className="menu__sign--in">
                  <i className="icon">
                    <SVGKey fill="white" />
                  </i>
                  { t('menu.signIn') }
                </a>
                <a href={`${DASHBOARD_LINK}/reg`} className="default-button menu__sign--up">
                  <span>{ t('menu.signUp') }</span>
                </a>
              </div>
              <LanguageSelector />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
