export default function SVGRocket({ gradient }) {
  return (
    <svg viewBox="0 0 15 15">
      <path d="M14.9,0.2C14.9,0.2,14.9,0.2,14.9,0.2c-0.1-0.1-0.1-0.1-0.1-0.1c-2-0.5-6.5,1.2-8.9,3.7C5.4,4.2,5,4.7,4.7,5.2c-0.8-0.1-1.5,0-2.2,0.3C0.7,6.3,0.2,8.4,0,9.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0l2.9-0.3c0,0.2,0,0.4,0,0.7c0,0.2,0.1,0.3,0.2,0.4l1.1,1.1c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.4,0,0.7,0l-0.3,2.9c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0.9-0.1,3-0.7,3.8-2.5c0.3-0.6,0.3-1.4,0.3-2.1c0.5-0.4,1-0.8,1.4-1.2C13.7,6.7,15.4,2.3,14.9,0.2z M8.8,6.2C8.5,6,8.4,5.7,8.3,5.4c-0.1-0.3,0-0.6,0.1-0.9C8.5,4.2,8.7,4,9,3.8c0.3-0.2,0.6-0.3,0.9-0.3c0.3,0,0.6,0.1,0.9,0.3C11,4,11.2,4.2,11.4,4.5c0.1,0.3,0.2,0.6,0.1,0.9C11.4,5.7,11.2,6,11,6.2c-0.1,0.1-0.3,0.3-0.5,0.3c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.6-0.1C9.1,6.5,8.9,6.4,8.8,6.2z" fill={!gradient ? 'currentColor' : 'url(#MenuMediaIcon)'} />
      <path d="M4.3,12.7C4.1,12.9,3.8,13,3.4,13c-0.9,0.1-1.6-0.6-1.5-1.5c0.1-0.3,0.2-0.8,0.3-0.9c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.5,0.1-0.9,0.3-1.3,0.6C0.1,11.9,0,15,0,15s3.1-0.1,4-0.9c0.3-0.3,0.6-0.8,0.6-1.3C4.6,12.7,4.4,12.6,4.3,12.7z" fill={!gradient ? 'currentColor' : 'url(#MenuMediaIcon)'} />
      <defs>
        <linearGradient id="MenuMediaIcon" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor="rgba(182, 109, 253, 1)" />
          <stop offset="100%" stopColor="rgba(119, 80, 157, 1)" />
        </linearGradient>
      </defs>
    </svg>
  );
}
