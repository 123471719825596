export default function SVGBinance() {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.9">
        <path d="M15.3438 30.6875C23.8179 30.6875 30.6875 23.8179 30.6875 15.3438C30.6875 6.86963 23.8179 0 15.3438 0C6.86963 0 0 6.86963 0 15.3438C0 23.8179 6.86963 30.6875 15.3438 30.6875Z" fill="#FFC075" />
        <path d="M15.3446 28.5719C22.6498 28.5719 28.5719 22.6498 28.5719 15.3446C28.5719 8.03928 22.6498 2.11719 15.3446 2.11719C8.03928 2.11719 2.11719 8.03928 2.11719 15.3446C2.11719 22.6498 8.03928 28.5719 15.3446 28.5719Z" fill="#FAAE54" />
        <path d="M15.3446 9.35851L19.4599 13.4738L21.7049 11.2294L15.3446 4.86914L8.98438 11.2294L11.2293 13.4738L15.3446 9.35851Z" fill="#F1F2F2" />
        <path d="M15.3446 21.3301L11.2293 17.2148L8.98438 19.4593L15.3446 25.8195L21.7049 19.4593L19.4599 17.2148L15.3446 21.3301Z" fill="#F1F2F2" />
        <path d="M13.0938 15.3457L15.3385 13.1009L17.5833 15.3457L15.3385 17.5905L13.0938 15.3457Z" fill="#F1F2F2" />
        <path d="M21.3281 15.3457L23.5729 13.1009L25.8176 15.3457L23.5729 17.5905L21.3281 15.3457Z" fill="#F1F2F2" />
        <path d="M4.86719 15.3438L7.11195 13.099L9.35671 15.3438L7.11195 17.5885L4.86719 15.3438Z" fill="#F1F2F2" />
      </g>
    </svg>
  );
}
