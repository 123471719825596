export default function SVGChineseFlag() {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 480.2 479.8">
      <g id="layer1" transform="translate(0 -540.362)">
        <g id="g3238">
          <g>
            <defs>
              <path d="M480.2,780.3c0,132.5-107.5,239.9-240.1,239.9S0,912.8,0,780.3l0,0c0-132.5,107.5-239.9,240.1-239.9S480.2,647.8,480.2,780.3L480.2,780.3z" />
            </defs>
            <g id="g29913" transform="matrix(1.348 0 0 1.348 -219.663 150.307)">
              <path id="path17502" fill="#E74C3C" d="M107.2,651.7h556.4v-371H107.2L107.2,651.7z" />
            </g>
          </g>
          <g id="g3231" transform="matrix(.874 0 0 .874 567.246 -73.389)">
            <path id="path7406" fill="#F1C40F" d="M-514.2,784.1l13.8,42.4h44.6l-36.1,26.2l13.7,42.4l-36-26.2l-36.1,26.2l13.8-42.4l-36.1-26.2h44.6L-514.2,784.1z" />
            <path id="path7408" fill="#F1C40F" d="M-398,761.9l-0.9,14.7l13.6,5.4l-14.2,3.6l-1,14.7l-7.8-12.4l-14.2,3.6l9.4-11.3l-7.8-12.4l13.6,5.5L-398,761.9z" />
            <path id="path7410" fill="#F1C40F" d="M-347.9,809.5l-6.7,13.1l10.3,10.4l-14.5-2.4l-6.7,13.1l-2.3-14.5l-14.5-2.3l13.1-6.6l-2.2-14.5l10.3,10.4L-347.9,809.5" />
            <path id="path7412" fill="#F1C40F" d="M-362.9,868.9l4.5,14l14.7,0l-11.9,8.6l4.5,14l-11.8-8.6l-11.9,8.6l4.6-14l-11.8-8.6l14.7,0L-362.9,868.9" />
            <path id="path7414" fill="#F1C40F" d="M-398,913.7l-1,14.7l13.6,5.5l-14.2,3.6l-0.9,14.7l-7.8-12.4l-14.2,3.6l9.4-11.3l-7.8-12.4l13.6,5.5L-398,913.7" />
          </g>
        </g>
      </g>
    </svg>
  );
}
