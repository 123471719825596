import { useState } from 'react';
import Modal from '../../Modal';
import useCustomTranslation from '../../../features/hooks/useCustomTranslation';

export default function PromoModal({ open = false, onClose = null }) {
  const { t } = useCustomTranslation();
  return (
    <Modal portal show={open} onClose={() => onClose && onClose()} customClass="--video">
      <iframe width="560" height="315" src={t('videos.promo')} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
    </Modal>
  );
}
