export default function SVGProfit() {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2144_52939)">
        <path d="M24.9687 12.0195C20.0253 12.0195 16.0039 16.0408 16.0039 20.9842C16.0039 25.9277 20.0252 29.949 24.9686 29.949C29.912 29.949 33.9997 25.9277 33.9997 20.9842C33.9997 16.0408 29.9121 12.0195 24.9687 12.0195ZM25.9909 25.7847C25.9823 25.7879 25.9733 25.7864 25.9646 25.7894V26.9607C25.9646 27.5113 25.5191 27.9568 24.9686 27.9568C24.418 27.9568 23.9725 27.5113 23.9725 26.9607V25.7826C23.3164 25.561 22.6878 25.1403 22.1505 24.4997C21.7964 24.0785 21.8508 23.4501 22.2731 23.097C22.6943 22.7429 23.3246 22.7974 23.6758 23.2196C24.203 23.847 24.7993 24.096 25.309 23.9132C25.701 23.7702 25.9646 23.3937 25.9646 22.9764C25.9646 22.4269 25.5181 21.9803 24.9686 21.9803C23.3207 21.9803 21.9803 20.6399 21.9803 18.9921C21.9803 17.7888 22.6963 16.7081 23.8042 16.2393C23.8591 16.2161 23.9172 16.2174 23.9725 16.1976V15.0078C23.9725 14.4572 24.418 14.0117 24.9686 14.0117C25.5192 14.0117 25.9647 14.4572 25.9647 15.0078V16.1994C26.4805 16.3737 26.9834 16.6506 27.4326 17.0856C27.8275 17.4679 27.8372 18.0982 27.4539 18.4941C27.0716 18.8891 26.4403 18.8978 26.0454 18.5155C25.561 18.0457 25.025 17.8842 24.5814 18.0739C24.2118 18.2305 23.9725 18.5904 23.9725 18.9922C23.9725 19.5418 24.419 19.9883 24.9686 19.9883C26.6164 19.9883 27.9568 21.3288 27.9568 22.9765C27.9569 24.2283 27.167 25.3567 25.9909 25.7847Z" fill="#77509D" />
        <path d="M0.996094 21.9805C0.44552 21.9805 0 22.426 0 22.9766V33.0038C0 33.5543 0.44552 33.9999 0.996094 33.9999H3.98431V21.9805H0.996094Z" fill="#77509D" />
        <path d="M23.6241 6.21725L16.6515 0.240756C16.2799 -0.080252 15.7274 -0.080252 15.3558 0.240756L8.38331 6.21725C8.06622 6.48766 7.9524 6.92734 8.0973 7.3184C8.24127 7.70947 8.6138 7.96918 9.03117 7.96918H12.0194C12.0194 16.5807 12.0194 25.3881 12.0194 34.0001C14.5855 34.0001 16.3595 34.0001 18.992 34.0001C19.5425 34.0001 19.9881 33.5546 19.9881 33.004V30.732C16.4458 28.9145 14.0116 25.2323 14.0116 20.9847C14.0116 16.7371 16.4459 13.0548 19.9881 11.2374V7.96925H22.9763C23.3936 7.96925 23.7662 7.70953 23.9102 7.31847C24.0551 6.92734 23.9413 6.48766 23.6241 6.21725Z" fill="#77509D" />
        <path d="M7.03906 14.0117C6.48849 14.0117 6.04297 14.4572 6.04297 15.0078V21.9804V33.9997C7.49793 33.9997 8.61462 33.9997 10.0273 33.9997V14.0117H7.03906Z" fill="#77509D" />
      </g>
      <defs>
        <clipPath id="clip0_2144_52939">
          <rect width="34" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
