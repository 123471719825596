export default function SVGSmartContract() {
  return (
    <svg width="66" height="64" viewBox="0 0 66 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.5966 23.7563C35.5829 23.7563 33.6144 24.3535 31.94 25.4722C30.2657 26.591 28.9607 28.1811 28.1901 30.0416C27.4195 31.902 27.2178 33.9492 27.6107 35.9242C28.0035 37.8992 28.9732 39.7134 30.3972 41.1373C31.8211 42.5612 33.6352 43.5309 35.6103 43.9238C37.5853 44.3167 39.6325 44.115 41.4929 43.3444C43.3533 42.5738 44.9435 41.2688 46.0622 39.5945C47.181 37.9201 47.7781 35.9516 47.7781 33.9379C47.7739 31.2389 46.6999 28.6516 44.7914 26.7431C42.8829 24.8346 40.2956 23.7605 37.5966 23.7563ZM43.253 36.2005C43.2503 37.0997 42.8919 37.9614 42.256 38.5973C41.6201 39.2332 40.7584 39.5916 39.8592 39.5943V40.7256H37.5966V39.5943H36.4653V40.7256H34.2027V39.5943H31.9402V37.3317H33.0715V30.544H31.9402V28.2815H34.2027V27.1502H36.4653V28.2815H37.5966V27.1502H39.8592V28.2815C40.7584 28.2842 41.6201 28.6426 42.256 29.2785C42.8919 29.9144 43.2503 30.776 43.253 31.6753C43.2508 32.5131 42.9362 33.3199 42.3706 33.9379C42.9362 34.5559 43.2508 35.3627 43.253 36.2005Z" fill="#F0DFFF" />
      <path d="M39.8611 35.0698H35.3359V37.3324H39.8611C40.1611 37.3324 40.4489 37.2132 40.661 37.001C40.8732 36.7889 40.9924 36.5011 40.9924 36.2011C40.9924 35.9011 40.8732 35.6133 40.661 35.4012C40.4489 35.189 40.1611 35.0698 39.8611 35.0698Z" fill="#F0DFFF" />
      <path d="M39.8611 30.5439H35.3359V32.8065H39.8611C40.1611 32.8065 40.4489 32.6873 40.661 32.4752C40.8732 32.263 40.9924 31.9753 40.9924 31.6752C40.9924 31.3752 40.8732 31.0874 40.661 30.8753C40.4489 30.6631 40.1611 30.5439 39.8611 30.5439Z" fill="#F0DFFF" />
      <path d="M37.5924 19.2319C34.6837 19.2319 31.8403 20.0945 29.4218 21.7105C27.0033 23.3264 25.1183 25.6233 24.0052 28.3106C22.8921 30.9979 22.6009 33.9549 23.1683 36.8077C23.7358 39.6606 25.1365 42.281 27.1932 44.3378C29.25 46.3946 31.8705 47.7952 34.7233 48.3627C37.5761 48.9302 40.5331 48.6389 43.2204 47.5258C45.9077 46.4127 48.2046 44.5277 49.8206 42.1092C51.4366 39.6907 52.2991 36.8473 52.2991 33.9386C52.2955 30.0393 50.7449 26.3006 47.9876 23.5434C45.2304 20.7861 41.4918 19.2355 37.5924 19.2319ZM37.5924 46.3827C35.1312 46.3827 32.7253 45.6529 30.6788 44.2855C28.6324 42.9181 27.0374 40.9746 26.0956 38.7008C25.1537 36.4269 24.9073 33.9248 25.3874 31.5109C25.8676 29.097 27.0528 26.8796 28.7931 25.1393C30.5334 23.399 32.7508 22.2138 35.1647 21.7336C37.5786 21.2535 40.0807 21.4999 42.3546 22.4418C44.6284 23.3836 46.5719 24.9786 47.9393 27.025C49.3067 29.0715 50.0365 31.4774 50.0365 33.9386C50.0344 37.2384 48.7227 40.4023 46.3894 42.7356C44.0562 45.0689 40.8922 46.3806 37.5924 46.3827Z" fill="#F0DFFF" />
      <path d="M13.8361 31.6759C13.8361 30.3334 13.438 29.0211 12.6922 27.9048C11.9464 26.7886 10.8863 25.9186 9.64598 25.4049C8.40569 24.8911 7.04091 24.7567 5.72423 25.0186C4.40754 25.2805 3.19809 25.927 2.24882 26.8763C1.29954 27.8255 0.653074 29.035 0.391169 30.3517C0.129264 31.6684 0.263683 33.0331 0.777428 34.2734C1.29117 35.5137 2.16117 36.5738 3.2774 37.3196C4.39363 38.0655 5.70596 38.4636 7.04844 38.4636C8.84865 38.4636 10.5751 37.7485 11.8481 36.4755C13.121 35.2026 13.8361 33.4761 13.8361 31.6759ZM5.35152 36.201C4.60143 36.201 3.88207 35.903 3.35167 35.3727C2.82128 34.8423 2.52331 34.1229 2.52331 33.3728V32.8072H4.78588V33.3728C4.78588 33.5228 4.84547 33.6667 4.95155 33.7728C5.05763 33.8789 5.2015 33.9384 5.35152 33.9384H8.74537C8.89538 33.9384 9.03926 33.8789 9.14534 33.7728C9.25141 33.6667 9.31101 33.5228 9.31101 33.3728C9.31101 33.2228 9.25141 33.0789 9.14534 32.9728C9.03926 32.8668 8.89538 32.8072 8.74537 32.8072H5.35152C4.60143 32.8072 3.88207 32.5092 3.35167 31.9788C2.82128 31.4484 2.52331 30.729 2.52331 29.979C2.52331 29.2289 2.82128 28.5095 3.35167 27.9791C3.88207 27.4487 4.60143 27.1508 5.35152 27.1508H5.91716V26.0195H8.17973V27.1508H8.74537C9.49545 27.1508 10.2148 27.4487 10.7452 27.9791C11.2756 28.5095 11.5736 29.2289 11.5736 29.979V30.5446H9.31101V29.979C9.31101 29.8289 9.25141 29.6851 9.14534 29.579C9.03926 29.4729 8.89538 29.4133 8.74537 29.4133H5.35152C5.2015 29.4133 5.05763 29.4729 4.95155 29.579C4.84547 29.6851 4.78588 29.8289 4.78588 29.979C4.78588 30.129 4.84547 30.2729 4.95155 30.3789C5.05763 30.485 5.2015 30.5446 5.35152 30.5446H8.74537C9.49545 30.5446 10.2148 30.8426 10.7452 31.373C11.2756 31.9034 11.5736 32.6227 11.5736 33.3728C11.5736 34.1229 11.2756 34.8423 10.7452 35.3727C10.2148 35.903 9.49545 36.201 8.74537 36.201H8.17973V37.3323H5.91716V36.201H5.35152Z" fill="#F0DFFF" />
      <path d="M55.6969 49.7754C54.3544 49.7754 53.0421 50.1735 51.9258 50.9193C50.8096 51.6652 49.9396 52.7253 49.4259 53.9655C48.9121 55.2058 48.7777 56.5706 49.0396 57.8873C49.3015 59.204 49.948 60.4134 50.8973 61.3627C51.8465 62.312 53.056 62.9585 54.3727 63.2204C55.6893 63.4823 57.0541 63.3478 58.2944 62.8341C59.5347 62.3204 60.5948 61.4504 61.3406 60.3341C62.0865 59.2179 62.4846 57.9056 62.4846 56.5631C62.4846 54.7629 61.7694 53.0364 60.4965 51.7635C59.2236 50.4905 57.4971 49.7754 55.6969 49.7754ZM60.222 55.4318H57.9594V54.8662C57.9594 54.7161 57.8999 54.5723 57.7938 54.4662C57.6877 54.3601 57.5438 54.3005 57.3938 54.3005H54C53.8499 54.3005 53.7061 54.3601 53.6 54.4662C53.4939 54.5723 53.4343 54.7161 53.4343 54.8662C53.4343 55.0162 53.4939 55.1601 53.6 55.2661C53.7061 55.3722 53.8499 55.4318 54 55.4318H57.3938C58.1439 55.4318 58.8633 55.7298 59.3937 56.2602C59.924 56.7906 60.222 57.5099 60.222 58.26C60.222 59.0101 59.924 59.7295 59.3937 60.2599C58.8633 60.7902 58.1439 61.0882 57.3938 61.0882H56.8282V62.2195H54.5656V61.0882H54C53.2499 61.0882 52.5305 60.7902 52.0001 60.2599C51.4697 59.7295 51.1717 59.0101 51.1717 58.26V57.6944H53.4343V58.26C53.4343 58.41 53.4939 58.5539 53.6 58.66C53.7061 58.7661 53.8499 58.8257 54 58.8257H57.3938C57.5438 58.8257 57.6877 58.7661 57.7938 58.66C57.8999 58.5539 57.9594 58.41 57.9594 58.26C57.9594 58.11 57.8999 57.9661 57.7938 57.86C57.6877 57.754 57.5438 57.6944 57.3938 57.6944H54C53.2499 57.6944 52.5305 57.3964 52.0001 56.866C51.4697 56.3356 51.1717 55.6163 51.1717 54.8662C51.1717 54.1161 51.4697 53.3967 52.0001 52.8663C52.5305 52.3359 53.2499 52.038 54 52.038H54.5656V50.9067H56.8282V52.038H57.3938C58.1439 52.038 58.8633 52.3359 59.3937 52.8663C59.924 53.3967 60.222 54.1161 60.222 54.8662V55.4318Z" fill="#F0DFFF" />
      <path d="M50.0367 13.5759C51.3792 13.5759 52.6915 13.1778 53.8078 12.432C54.924 11.6861 55.794 10.626 56.3077 9.38573C56.8215 8.14544 56.9559 6.78066 56.694 5.46397C56.4321 4.14729 55.7856 2.93784 54.8363 1.98856C53.8871 1.03929 52.6776 0.39282 51.3609 0.130915C50.0443 -0.13099 48.6795 0.0034293 47.4392 0.517174C46.1989 1.03092 45.1388 1.90092 44.393 3.01715C43.6471 4.13338 43.249 5.44571 43.249 6.78819C43.249 8.5884 43.9642 10.3149 45.2371 11.5878C46.51 12.8608 48.2365 13.5759 50.0367 13.5759ZM45.5116 7.91947H47.7742V8.48511C47.7742 8.63513 47.8337 8.779 47.9398 8.88508C48.0459 8.99116 48.1898 9.05075 48.3398 9.05075H51.7336C51.8837 9.05075 52.0275 8.99116 52.1336 8.88508C52.2397 8.779 52.2993 8.63513 52.2993 8.48511C52.2993 8.3351 52.2397 8.19122 52.1336 8.08514C52.0275 7.97906 51.8837 7.91947 51.7336 7.91947H48.3398C47.5897 7.91947 46.8703 7.6215 46.3399 7.09111C45.8096 6.56072 45.5116 5.84135 45.5116 5.09126C45.5116 4.34118 45.8096 3.62181 46.3399 3.09142C46.8703 2.56103 47.5897 2.26306 48.3398 2.26306H48.9054V1.13177H51.168V2.26306H51.7336C52.4837 2.26306 53.2031 2.56103 53.7335 3.09142C54.2639 3.62181 54.5619 4.34118 54.5619 5.09126V5.65691H52.2993V5.09126C52.2993 4.94125 52.2397 4.79737 52.1336 4.69129C52.0275 4.58522 51.8837 4.52562 51.7336 4.52562H48.3398C48.1898 4.52562 48.0459 4.58522 47.9398 4.69129C47.8337 4.79737 47.7742 4.94125 47.7742 5.09126C47.7742 5.24128 47.8337 5.38516 47.9398 5.49123C48.0459 5.59731 48.1898 5.65691 48.3398 5.65691H51.7336C52.4837 5.65691 53.2031 5.95488 53.7335 6.48527C54.2639 7.01566 54.5619 7.73503 54.5619 8.48511C54.5619 9.2352 54.2639 9.95457 53.7335 10.485C53.2031 11.0153 52.4837 11.3133 51.7336 11.3133H51.168V12.4446H48.9054V11.3133H48.3398C47.5897 11.3133 46.8703 11.0153 46.3399 10.485C45.8096 9.95457 45.5116 9.2352 45.5116 8.48511V7.91947Z" fill="#F0DFFF" />
      <path d="M36.459 6.7872C37.1853 6.7872 37.9173 6.81548 38.6322 6.87318L38.811 4.6174C38.0361 4.55518 37.2509 4.52463 36.459 4.52463C30.951 4.50326 25.5489 6.03796 20.8748 8.95203C16.2006 11.8661 12.4446 16.041 10.0391 20.9961L12.0754 21.9916C14.2956 17.4184 17.7621 13.565 22.0759 10.8752C26.3897 8.1853 31.3754 6.76827 36.459 6.7872Z" fill="#F0DFFF" />
      <path d="M36.4576 61.0894C30.5871 61.0697 24.8796 59.1571 20.1826 55.6356C15.4855 52.1141 12.0496 47.1715 10.385 41.542L8.21289 42.1732C9.32674 45.98 11.1981 49.5225 13.7148 52.5883C16.2314 55.654 19.3414 58.1797 22.8582 60.0139C26.375 61.8481 30.2261 62.953 34.1804 63.2622C38.1347 63.5714 42.1107 63.0787 45.8699 61.8134L45.1459 59.664C42.3469 60.6097 39.4121 61.0912 36.4576 61.0894Z" fill="#F0DFFF" />
      <path d="M63.6123 33.9386C63.6126 38.4927 62.4689 42.9739 60.2863 46.971L62.2706 48.057C65.2293 42.6429 66.4114 36.4357 65.6499 30.3131C64.8885 24.1904 62.2219 18.462 58.0271 13.9375L56.3721 15.476C61.0348 20.4923 63.6222 27.0899 63.6123 33.9386Z" fill="#F0DFFF" />
      <path d="M12.7031 2.26221H14.9657V4.52477H12.7031V2.26221Z" fill="#F0DFFF" />
      <path d="M12.7031 6.78809H14.9657V9.05065H12.7031V6.78809Z" fill="#F0DFFF" />
      <path d="M10.4424 4.52441H12.7049V6.78698H10.4424V4.52441Z" fill="#F0DFFF" />
      <path d="M14.9648 4.52441H17.2274V6.78698H14.9648V4.52441Z" fill="#F0DFFF" />
      <path d="M4.79004 55.4336H7.0526V57.6962H4.79004V55.4336Z" fill="#F0DFFF" />
      <path d="M4.79004 59.9585H7.0526V62.2211H4.79004V59.9585Z" fill="#F0DFFF" />
      <path d="M2.52148 57.6953H4.78405V59.9579H2.52148V57.6953Z" fill="#F0DFFF" />
      <path d="M7.04297 57.6953H9.30553V59.9579H7.04297V57.6953Z" fill="#F0DFFF" />
    </svg>
  );
}
